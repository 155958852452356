<template id="ListJobs">
    <div>
        <!-- Errori -->
        <b-alert v-if="errors && errors.length" variant="danger" show dismissible>
            <ul>
                <li v-for="error of errors" :key="error.id">
                    Errore : {{error.message}}
                </li>
            </ul>
        </b-alert>


        <b-card noBody>
            <b-tabs card fill>
                <b-tab title="Scheduled Jobs" active>

                    <h1>Scheduled Jobs</h1>
                    <div class="container">
                        <b-table :items="this.$store.state.SchedulerJobs" :primary-key="'id'" :fields="sfields" class="table_Cell">


                            <!-- enqueuedtime -->
                            <template v-slot:cell(enqueuedtime)="data">
                                {{FormatDate(data.item.enqueuedtime)}}
                            </template>

                            <!-- Job Type -->
                            <template v-slot:cell(jobtype)="data">
                                <font-awesome-icon size="lg" class="icon_azure" :icon="GetJobTypeIcon(data.item.fktype)"  />
                            </template>

                            <!-- Job Type -->
                            <template v-slot:cell(jobdesc)="data">
                                {{GetSchedJobDescription(data.item)}}
                            </template>




                            <!-- Job Status -->
                            <template v-slot:cell(fkstatus)="data">
                                <font-awesome-icon size="lg" :class="GetStatusColor(data.item.fkstatus)" :icon="GetStatusIcon(data.item.fkstatus)"  />
                            </template>



                            <template #cell(message)="data">

                                <font-awesome-icon size="lg" class="icon_azure" icon="info-circle" @click="data.toggleDetails" />

                            </template>

                            <template #row-details="data">
                                <b-card>
                                    <font-awesome-icon size="lg" class="icon_red" icon="window-close" @click="data.toggleDetails" />

                                    <!-- Status -->
                                    <b-row class="mb-2">
                                        <b-col sm="3" class="text-sm-right"><b>Status:</b></b-col>
                                        <b-col>{{GetJobStatusTypesDesc(data.item.fkstatus)}}</b-col>

                                        <b-col sm="1" class="text-sm-right"><b>Code:</b></b-col>
                                        <b-col>{{data.item.fkstatus}}</b-col>

                                    </b-row>

                                    <!-- Time Info -->
                                    <b-row class="mb-2">
                                        <b-col sm="3" class="text-sm-right"><b>Start:</b></b-col>
                                        <b-col>{{FormatDate(data.item.startedtime)}}</b-col>

                                        <b-col sm="1" class="text-sm-right"><b>End:</b></b-col>
                                        <b-col>{{FormatDate(data.item.endedtime)}}</b-col>

                                    </b-row>

                                    <b-row class="mb-8">
                                        <b-col sm="3" class="text-sm-right"><b>Message:</b></b-col>
                                        <b-col>{{ data.item.message }}</b-col>
                                    </b-row>

                                    <b-row v-if="data.item.fkstatus == 140" class="mb-8">
                                        <b-col sm="3" class="text-sm-right"><b>Answer:</b></b-col>
                                        <b-col sm="1"><b-button variant="outline-success" @click="SetAnswer(data.item.id,true)">Yes</b-button></b-col>
                                        <b-col sm="3"><b-button variant="outline-danger" @click="SetAnswer(data.item.id,false)">No</b-button></b-col>
                                    </b-row>

                                </b-card>
                            </template>

                        </b-table>
                    </div>



                </b-tab>

                <b-tab title="Exec New Job">

                    <b-card-group columns>
                        <template v-for="(rifjob, index) in this.RunningJobs">
                            <JobStatusCard :rifjob="rifjob" :key="index"></JobStatusCard>
                        </template>
                    </b-card-group>

                    <h1>Jobs</h1>
                    <div class="container">
                        <b-table :items="this.$store.state.Jobs" :primary-key="'id'" :fields="fields" class="table_Cell">
                            <!-- A virtual composite column -->
                            <template v-slot:cell(id)="data">
                                <font-awesome-icon size="lg" icon="play" @click="ExecuteJob(data.item.id)" style="color:forestgreen" />
                            </template>
                        </b-table>
                    </div>

                </b-tab>                
            </b-tabs>
        </b-card>


        



        


        


    </div>
</template>

<style>
    .icon_red {        
        color: red;
    }
    .icon_orange {
        color: orange;
    }
    .icon_green {
        color: forestgreen;
    }
    .icon_azure {
        color: darkturquoise;
    }
</style>
<script>
    import axios from 'axios';
    import moment from 'moment';
    import JobStatusCard from './JobStatusCard.vue';
    export default {
        data() {
            return {
                errors: [],
                fields: [
                    {
                        key: 'description',
                        label: 'Job Name',
                        sortable: true
                    },
                    {
                        key: 'id',
                        sortable: false
                    },
                    
                ],
                sfields: [
                    {
                        key: 'enqueuedtime',
                        label: 'Date Time',
                        sortable: true
                    },
                    {
                        key: 'jobtype',
                        label: 'Type',
                        sortable: true
                    },
                    {
                        key: 'jobdesc',
                        label: 'Description',
                        sortable: true
                    },             
                    {
                        key: 'fkstatus',
                        label: 'Status',
                        sortable: true
                    },
                    {
                        key: 'message',
                        label: 'Info',
                        sortable: false
                    },
        

                ],
                sIcons: [
                    {
                        id: 100,
                        icon: 'clock',
                        class: 'icon_azure'
                    },
                    {
                        id: 140,
                        icon: 'question-circle',
                        class: 'icon_orange'
                    },
                    {
                        id: 150,
                        icon: 'play',
                        class: 'icon_green'
                    },
                    {
                        id: 220,
                        icon: 'exclamation-triangle',
                        class: 'icon_red'
                    },
                    {
                        id: 240,
                        icon: 'exclamation',
                        class: 'icon_orange'
                    },
                    {
                        id: 250,
                        icon: 'check-circle',
                        class: 'icon_green'
                    },
                ],
                RunningJobs: [],
                JobStatus: {}
            }
        },
        components: {
            JobStatusCard
        },
        methods: {
            ExecuteJob: function (idjob) {
                console.log('idjob : ' + idjob);
                let self = this;
                let jsonParams = JSON.stringify({});                

                // Se ci sono dei parametri li copio dalla definizione del Job
                let tJobDef = this.$store.state.Jobs.find(e => e.id == idjob);     
                if (Object.prototype.hasOwnProperty.call(tJobDef, 'jsonparams')) {
                    if (tJobDef.jsonparams) {
                        jsonParams = tJobDef.jsonparams;
                    }
                }
                
                var Link = 'api/_main/Jobs/runjob/' + idjob;
                axios.post(Link, "jsonParams=" + jsonParams, this.$store.state.Headers)
                    .then(response => {
                        var tResultId = response.data.Result;
                        console.log('Response idjob : ' + tResultId);
                        self.GetJobStatus(tResultId);
                        self.RunningJobs.push(tResultId);
                        
                    })
                    .catch(e => {
                        this.errors.push(e)
                    })
            },
            FormatDate: function (val) {
                if (val) {
                    return moment(String(val)).format('DD/MM hh:mm');
                }
            },
            GetJobTypeIcon: function (idtype) {
                if (idtype == 1) {
                    return "cogs";
                }
                else {
                    return "file-import";
                }                
            },
            GetJobStatus: function (idjobstatus) {
                if (idjobstatus) {
                    let self = this;
                    var Link = 'api/_main/SchedulerJob/' + idjobstatus;
                    axios.get(Link, this.$store.state.Headers)
                        .then(response => {
                            self.JobStatus = response.data.Result;                            
                        }).catch(e => {
                            this.errors.push(e);
                        });
                }

            },
            GetJobStatusTypesDesc: function (idtype) {
                return this.$store.state.JobStatusTypes.find(e => e.id == idtype).description;
            },
            GetSchedJobDescription: function (item) {                                        
                //Deve rimamanere solo description, appena � stato implementato...
                
                if (Object.prototype.hasOwnProperty.call(item, 'description')) {                
                    return item.description;
                }

                if (Object.prototype.hasOwnProperty.call(item, 'fkschedulerjobtemplate')) {                    
                    return this.$store.state.Jobs.find(e => e.id == item.fkschedulerjobtemplate).description;
                }

                if (Object.prototype.hasOwnProperty.call(item, 'fkfileloaderlayout')) {                    
                    return this.$store.state.FileLoaderLayouts.find(e => e.id == item.fkfileloaderlayout).label;
                }
                return item.message;
               
                                 
            },       
            GetStatusIcon: function (idstatus) {
                
                var tIcon = this.sIcons.find(e => e.id == idstatus);                
                if (tIcon) {                
                    return tIcon.icon;
                }
                else {
                    return 'bug';
                }
            },
            GetStatusColor: function (idstatus) {                
                var tIcon = this.sIcons.find(e => e.id == idstatus);                
                if (tIcon) {                
                    return tIcon.class;
                }
                else {
                    return 'icon_green';
                }
            },
            SetAnswer: function (parId, parAnswer) {
                console.log('SetAnswer : ' + parId + ' , ' + parAnswer);
                //let self = this;
                var Link = 'api/_main/Jobs/jobproceed/' + parId;
                if (!parAnswer) {
                    Link = 'api/_main/Jobs/jobabort/' + parId;
                }                
                axios.get(Link, this.$store.state.Headers)
                    .then(response => {
                        console.log(response);
                        //self.JobStatus = response.data.Result;
                    }).catch(e => {
                        this.errors.push(e);
                    });
            }

  
        }
    }
</script>