<template id="BitProp">
    <div>        
        <font-awesome-icon v-if="value == undefined" size="lg" icon="circle" @click="ViewList()" style="color:gray" />
        <font-awesome-icon v-if="value == 0" size="lg" icon="check-circle" @click="ViewList()" style="color:forestgreen" />
        <font-awesome-icon v-if="value == 1" size="lg" icon="exclamation-circle" @click="ViewList()" style="color:orange" />
        <font-awesome-icon v-if="value == 2" size="lg" icon="stop" @click="ViewList()" style="color:red" />

        <div v-if="SwiList" class="bitprop ui selection dropdown active visible" style="position: absolute; z-index: 99;">
            <table class="ui table">
                <tbody>
                    <tr>
                        <td v-if="ShowUndefined" @click="UpdateValue(undefined)"><font-awesome-icon size="lg" icon="circle" style="color:gray" /></td>
                    </tr>                    
                    <tr>
                        <td @click="UpdateValue(0)"><font-awesome-icon size="lg" icon="check-circle" style="color:forestgreen" /></td>
                    </tr>
                    <tr>
                        <td @click="UpdateValue(1)"><font-awesome-icon size="lg" icon="exclamation-circle" style="color:orange" /></td>
                    </tr>
                    <tr>
                        <td @click="UpdateValue(2)"><font-awesome-icon size="lg" icon="stop" style="color:red" /></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>    

    export default {
        props: {
            value: {
                //type: Number,
                required: true
            },
            ShowUndefined: {
                type: Boolean,
                required: true
            },
        },
        data() {
            return {
                SwiList: false,
            };
        },
        components: {            
        },
        watch: {
        },

        methods: {
            ViewList() {

                var elements = document.getElementsByClassName('bitprop')

                for (var i = 0; i < elements.length; i++) {
                    elements[i].style.display = 'none';
                }

                this.SwiList = !this.SwiList;
            },
            UpdateValue(newvalue) {
                this.SwiList = false;
                this.$emit('input', newvalue);
                this.$emit('change', newvalue);
            }
            
        },
        mounted() {
            //console.log('mounted bitprop');
        },
        updated() {
            //console.log(this.$route)
        }


    }


</script>