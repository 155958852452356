<template id="JobStatusCard">
    <b-card style="max-width: 20rem;" :title="JobDescription">
        <!-- Errori -->
        <b-alert v-if="errors && errors.length" variant="danger" show dismissible>
            <ul>
                <li v-for="error of errors" :key="error.id">
                    Errore : {{error.message}}
                </li>
            </ul>
        </b-alert>
  
        <div v-if="JobStatus">

            {{JobStatus.message}}
            {{GetJobStatusDescription(JobStatus.fkstatus)}}
            
            
            <div v-if="JobStatus.fkstatus == 140">
                <span>Answer:</span>                
                <b-button variant="outline-success" @click="SetAnswer(JobStatus.id,true)">Yes</b-button>
                <b-button variant="outline-danger" @click="SetAnswer(JobStatus.id,false)">No</b-button>                
            </div>
            <div v-else>
                <b-progress :value="100" :variant="GetProgresscolor(JobStatus.fkstatus)" striped :animated="JobStatus.fkstatus==150"></b-progress>    
            </div>

            <font-awesome-icon size="lg" class="icon_azure" icon="sync-alt" @click="GetJobStatus()" />
        </div>
    </b-card>
</template>

<script>
    import axios from 'axios';
    export default {
        props: {
            rifjob: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                errors: [],       
                timer: '',
                JobStatus: {},
                JobDescription: ''
            }
        },
        watch: {
            rifjob: {
                immediate: true,
                deep: true,
                handler(newVal) {
                    if (newVal != 0) {
                        console.log('rifjob : ' + newVal);
                        this.GetJobStatus();
                        this.timer = setInterval(this.GetJobStatus, 10000)
                    }
                    else {
                        clearInterval(this.timer);
                    }
                }
            }
        },
        beforeDestroy() {
            clearInterval(this.timer);
        },
        methods: {
            cancelAutoUpdate() { clearInterval(this.timer) },
            GetJobStatus() {                
                console.log('GetJobStatus : ' + this.rifjob);
                let self = this;
                var Link = 'api/_main/SchedulerJob/' + this.rifjob;
                axios.get(Link, this.$store.state.Headers)
                    .then(response => {
                        self.JobStatus = response.data.Result;
                        //self.JobDescription = self.GetTitle(self.JobStatus.fktype, self.JobStatus.fkschedulerjobtemplate);

                        var tTitle = 'N.A';

                        
                        if (Object.prototype.hasOwnProperty.call(self.JobStatus, 'fkschedulerjobtemplate')) {
                            tTitle = this.$store.state.Jobs.find(E => E.id === self.JobStatus.fkschedulerjobtemplate).description;
                        }

                        if (Object.prototype.hasOwnProperty.call(self.JobStatus, 'fkfileloaderlayout')) {
                            tTitle = this.$store.state.FileLoaderLayouts.find(E => E.id === self.JobStatus.fkfileloaderlayout).label;
                        }
                            
                        
                                                
                        self.JobDescription = tTitle;   



                        if (self.JobStatus.fkstatus == 220) {
                            clearInterval(this.timer);
                        }

                        if (self.JobStatus.fkstatus == 250) {
                            clearInterval(this.timer);
                        }

                    }).catch(e => {
                        this.errors.push(e);
                    });
                

            },
            GetJobStatusDescription: function (idjobstatustype) {
                if (idjobstatustype) {
                    var tStatus = this.$store.state.JobStatusTypes.find(E => E.id === idjobstatustype);
                    return tStatus.description;
                }
                else {
                    return '';
                }
            },
            GetProgresscolor: function (idjobstatustype) {
                var tVariant = 'secondary';
                //console.log('idjobstatustype ' + idjobstatustype);

                if (idjobstatustype == 150) {
                    tVariant = 'primary';
                }

                if (idjobstatustype == 220) {
                    tVariant = 'danger';
                }

                if (idjobstatustype == 250) {
                    tVariant = 'success';
                }

                console.log(tVariant);
                return tVariant;
            },
            SetAnswer: function (parId, parAnswer) {
                console.log('SetAnswer : ' + parId + ' , ' + parAnswer);
                //let self = this;
                var Link = 'api/_main/Jobs/jobproceed/' + parId;
                if (!parAnswer) {
                    Link = 'api/_main/Jobs/jobabort/' + parId;
                }
                axios.get(Link, this.$store.state.Headers)
                    .then(response => {
                        console.log(response);
                        //self.JobStatus = response.data.Result;
                    }).catch(e => {
                        this.errors.push(e);
                    });
            }
            

        }
    }
</script>