<template>
    <div id="app">
        <div class="menu-container">
            <Menu :LoggedIn="this.$store.state.LoggedIn"></Menu>
        </div>
        <div class="main-container">
            <router-view />
        </div>
    </div>
</template>
<script>
    import axios from 'axios';
    import Menu from './Menu.vue';
    export default {
        components: { Menu },
        updated() {            
            console.log('updated app');
            //Si potrebbe controllare il cookie per non fare la chiamata se non serve...
            if (!this.$store.state.LoggedIn) {
                var Link = 'api/_main/Account/login';
                axios.get(Link)
                    .then(response => {
                        var tParams = {};
                        tParams.Token = response.data.Result.id;
                        tParams.Username = response.data.Result.name;
                        this.$store.commit('SetLoginParams', tParams);
                        this.$store.commit('SetAllowPasswordChange', response.data.Result.allowPasswordChange);
                        this.$store.dispatch('GetCompanies');
                    }).catch(e => {
                        console.log(e);
                    });
            }            
        }
    }
</script>

<style>

    html, body {
        width: 100%;
        height: 100%;
        position: relative;
    }
    body {
        overflow: hidden;
    }
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        width: 100%;
        height: 100%;
    }

    #app > .menu-container {
        flex: 0 0 auto;
    }

    #app > .main-container {
        position: relative;
        flex: 1 1 auto;
        overflow-x: hidden;
        overflow-y: auto;
    }
</style>


