<template id="ListUsers">
    <div>
        <!-- Errori -->
        <b-alert v-if="errors && errors.length" variant="danger" show dismissible>
            <ul>
                <li v-for="error of errors" :key="error.id">
                    Errore : {{error.message}}
                </li>
            </ul>
        </b-alert>


        <h2 class="text-center mt-3 mb-2">
            Utenti
            <b-button title="Nuovo Utente" pill variant="outline-success" style="margin-left: 12px;" align="right" @click="NuovoUtente()" v-b-modal.modal-edit><font-awesome-icon size="sm" icon="plus" /></b-button>
        </h2>
        <!--Spinner-->
        <div v-if="isLoading" id="div-spinner" style="position:fixed;top:50%;left:50%">
            <b-spinner variant="primary" style="width: 3rem; height: 3rem;" label="$t('messages.RicercaInCorso')"></b-spinner>
        </div>
        <div v-else>
            <div class="container">
                <b-table :items="Utenti" :fields="fields"  hover  no-footer-sorting sort-icon-left responsive class="table col-sm-8 offset-sm-2">
                    

                    <template v-slot:cell(name)="data">
                        <b-link active-class="ptobsliml" class="grayelement" href="#foo" @click="OpenModal(data.item)">{{data.item.name}}</b-link>                        
                    </template>

                    <template v-slot:cell(swiAmm)="data">
                        <b-form-checkbox :id="'cb_swiread'+data.item.id"
                                         variant="warning"
                                         v-model="data.item.swiAmm"
                                         :name="'cb_swiread-'+data.item.id"                                         
                                         unchecked-value="false">
                        </b-form-checkbox>
                    </template>

                    <template v-slot:cell(swiRv)="data">
                        <b-form-checkbox :id="'cb_swiread'+data.item.id"
                                         v-model="data.item.swiRv"
                                         :name="'cb_swiread-'+data.item.id"
                                         value="true"
                                         unchecked-value="false">
                        </b-form-checkbox>
                    </template>

                    <template v-slot:cell(swiAream)="data">
                        <b-form-checkbox :id="'cb_swiread'+data.item.id"
                                         v-model="data.item.swiAream"
                                         :name="'cb_swiread-'+data.item.id"
                                         value="true"
                                         unchecked-value="false">
                        </b-form-checkbox>
                    </template>

                    <template v-slot:cell(swiAge)="data">
                        <b-form-checkbox :id="'cb_swiread'+data.item.id"
                                         v-model="data.item.swiAge"
                                         :name="'cb_swiread-'+data.item.id"
                                         value="true"
                                         unchecked-value="false">
                        </b-form-checkbox>
                    </template>



                    <template v-slot:cell(swiread)="data">
                        <b-form-checkbox :id="'cb_swiread'+data.item.id"
                                         v-model="data.item.swiread"
                                         :name="'cb_swiread-'+data.item.id"
                                         value="true"
                                         unchecked-value="false">
                        </b-form-checkbox>
                    </template>
                    <template v-slot:cell(swiwri)="data">
                        <b-form-checkbox :id="'cb_swiwri'+data.item.id"
                                         v-model="data.item.swiwri"
                                         :name="'cb_swiwri-'+data.item.id"
                                         value="true"
                                         unchecked-value="false">
                        </b-form-checkbox>
                    </template>



                    <template v-slot:cell(datfin)="data">
                        <span>{{GetDataFormatted(data.item.datfin)}}</span>
                    </template>

                    <template v-slot:cell(id)="data">                        
                        <font-awesome-icon  icon="edit" @click="OpenModal(data.item)" style="color:#adb5bd" />
                    </template>

         


                </b-table>


            </div>
            <b-modal id="modal-edit" :title="'Utente : ' + UtenteSel.name" ref="modal" size="lg"
                     @ok="handleOk">
                <form ref="form" @submit.stop.prevent="handleSubmit">
                    <b-form-group label="Nome"
                                  :state="State"
                                  label-for="name-input"
                                  label-cols-sm="4"
                                  label-cols-lg="3"
                                  invalid-feedback="Nome is required"
                                  :horizontal="true">
                        <b-form-input id="name-input" v-model="UtenteSel.name" :state="State" required></b-form-input>
                    </b-form-group>

                    <b-form-group label="Domain User"
                                  :state="State"
                                  label-for="swiDom_chkbox"
                                  label-cols-sm="4"
                                  label-cols-lg="3"
                                  invalid-feedback="Nome is required"
                                  :horizontal="true">
                        <b-form-checkbox switch id="swiDom_chkbox" v-model="UtenteSel.swiDom"></b-form-checkbox>
                    </b-form-group>

                    <b-form-group label="Admin"
                                  label-cols-sm="4"
                                  label-cols-lg="3"
                                  label-for="swiAmm_chkbox" :horizontal="true">
                        <b-form-checkbox id="swiAmm_chkbox" v-model="UtenteSel.swiAmm"></b-form-checkbox>
                    </b-form-group>

                    <b-form-group label="R. Vendite"
                                  label-cols-sm="4"
                                  label-cols-lg="3"
                                  label-for="swiRv_chkbox" :horizontal="true">
                        <b-form-checkbox id="swiRv_chkbox" v-model="UtenteSel.swiRv"></b-form-checkbox>
                    </b-form-group>

                    <b-form-group label="Area Manager"
                                  label-cols-sm="4"
                                  label-cols-lg="3"
                                  label-for="swiAream_chkbox" :horizontal="true">
                        <b-form-checkbox id="swiAream_chkbox" v-model="UtenteSel.swiAream"></b-form-checkbox>
                    </b-form-group>


                    <b-form-group v-if="UtenteSel.swiAream"
                                  label="Codice Area Manager"
                                  label-cols-sm="4"
                                  label-cols-lg="3"
                                  label-for="aream-input" :horizontal="true">
                        <b-form-input id="aream-input" v-model="UtenteSel.codaream" :state="State" required></b-form-input>
                    </b-form-group>

                    <b-form-group label="Agente"
                                  label-cols-sm="4"
                                  label-cols-lg="3"
                                  label-for="swiAge_chkbox" :horizontal="true">
                        <b-form-checkbox id="swiAge_chkbox" v-model="UtenteSel.swiAge"></b-form-checkbox>
                    </b-form-group>

                    <b-form-group v-if="UtenteSel.swiAge"
                                  label="Codice Agente"
                                  label-cols-sm="4"
                                  label-cols-lg="3"
                                  label-for="age-input" :horizontal="true">
                        <b-form-input id="age-input" v-model="UtenteSel.codage" :state="State" required></b-form-input>
                    </b-form-group>


                    <b-form-group v-if="SwiResetPassword"
                                  label="New Password"
                                  label-cols-sm="4"
                                  label-cols-lg="3"
                                  label-for="pwd-input" :horizontal="true">
                        <b-form-input id="pwd-input" v-model="NewPassword" style = "width: 50%; display: inline;" :state="State" required></b-form-input>
                        <b-button @click="ResetPassword" style="margin-left: 10px" variant="success">
                            <font-awesome-icon size="sm" icon="save" /> Save
                        </b-button>
                    </b-form-group>


                </form>


                <template v-slot:modal-footer>

                    <div v-if="SwiDel">
                        <font-awesome-icon variant="danger" size="lg" icon="exclamation-triangle" style="color:red" /> <b>Are you sure ?  </b>
                        <b-button @click="Close">
                            Cancel
                        </b-button>
                    </div>
                    <b-button v-if="!isNew" variant="danger" class="mr-auto" @click="Delete">
                        <font-awesome-icon size="sm" icon="trash-alt" /> Delete
                    </b-button>

                    <b-button v-if="!SwiDel && !UtenteSel.swiDom && !SwiResetPassword" variant="info" @click="SwiResetPassword=true">
                        <font-awesome-icon size="sm" icon="key" /> Reset Password
                    </b-button>

                    <b-button v-if="!SwiDel" variant="success" @click="SaveRec">
                        <font-awesome-icon size="sm" icon="save" /> Save
                    </b-button>
                    <b-button v-if="!SwiDel" @click="Close">
                        Cancel
                    </b-button>



                </template>

            </b-modal>
        </div>
    </div>
</template>

<style>

    .grayelement {
        color: rgb(50, 49, 48);        
    }

    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
        color: #fff;
        border-color: orange;
        background-color: orange;
    }

    .icon {
        color: red;
        margin-left: 10px;
    }
    .iconlist {
        color: darkslategray;
        margin-left: 10px;
        margin-right: 10px;
    }
    .vdatetime-input {
        border: 0;
        text-align: center;
        width: 100%;
    }

    .table_header
    {
        font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
        font-size: 0.8rem;        
        color: rgb(50, 49, 48);
        white-space: nowrap;
        text-align: left;
        cursor: default;
    }
    
    
</style>

<script>    
    
    import axios from 'axios';
    import moment from 'moment';
    
    export default {
        name: 'ListUsers',
        data() {
            return {
                errors: [],
                UtenteSel: {},
                Utenti: [],
                fields: [                    
                    {
                        key: 'name',
                        label: 'Nome Utente',
                        thClass: 'table_header',
                        sortable: true
                    },
                    {
                        key: 'swiAmm',
                        label: 'Admin',
                        thStyle: "width: 15%",
                        thClass: 'table_header',
                        sortable: true      
                        
                    },
                    {
                        key: 'swiRv',
                        label: 'R. Vendite',
                        thStyle: "width: 15%",
                        thClass: 'table_header',
                        sortable: true
                    },
                    {
                        key: 'swiAream',
                        label: 'Area Manager',
                        thStyle: "width: 15%",
                        thClass: 'table_header',
                        sortable: true
                    },
                    {
                        key: 'swiAge',
                        label: 'Agente',
                        thStyle: "width: 15%",
                        thClass: 'table_header',
                        sortable: true
                    },
                    {
                        key: 'id',
                        label: '',
                        thStyle: "width: 5%",
                        sortable: false
                    }],
                isLoading: false,   
                NewPassword: '',
                State: null,
                SwiDel: false,
                SwiResetPassword: false,
                isNew: false
            };
        },
        components: {            
        },
        watch: {            
        },

        methods: {
            Close() {
                this.SwiDel = false;
                this.isNew = false;
                this.resetModal();
                this.$bvModal.hide("modal-edit")
            },
            GetUtenti() {
                let self = this;
                var Link = '/api/v1/Account';
                axios.get(Link)
                    .then(response => {
                        self.Utenti = response.data.result;
                    }).catch(e => {
                        self.errors.push(e)
                    });
            },
            GetDataFormatted(parData) {
                return parData !== null ? moment(parData).format('YYYY-MM-DD') : '';
            },
            checkFormValidity() {
                const valid = this.$refs.form.checkValidity()
                this.State = valid
                return valid
            },
            resetModal() {
                this.UtenteSel = {};
                this.State = null;
                this.SwiResetPassword = false;
            },
            ResetPassword() {
                let self = this;                
                var Link = '/api/v1/Account/resetpassword';
                
                var tPassword = {};
                tPassword.id = self.UtenteSel.id;
                tPassword.password = self.NewPassword;
                axios.post(Link,tPassword)
                    .then(() => {
                        self.Close();
                    }).catch(e => {
                        self.errors.push(e)
                    });
            },        
            handleOk(bvModalEvt) {
                // Prevent modal from closing
                bvModalEvt.preventDefault()
                // Trigger submit handler
                this.handleSubmit()
            },
            handleSubmit() {
                let self=this;
                // Exit when the form isn't valid
                if (!self.checkFormValidity()) {
                return
                }               
                this.SaveRec();
            },
            SaveRec() {
                let self = this;
                self.UtenteSel.identitySource = (self.UtenteSel.swiDom ? 1 : 0);                
                if (self.isNew) {                    
                    axios.post('/api/v1/Account', self.UtenteSel)
                        .then(resp => {
                            if (resp.data.result > 0) {
                                // Hide the modal manually
                                self.$nextTick(() => {
                                    self.Close();
                                    //self.isNew = false;
                                    //self.resetModal();
                                    //self.$bvModal.hide("modal-edit")                                    
                                })
                            }
                            else {
                                alert("Utente non salvato");
                            }
                            self.GetUtenti();

                        })
                        .catch(e => {
                            if (e.response) {                                
                                if (Object.prototype.hasOwnProperty.call(e.response.data, 'isError')) {                                    
                                    self.errors.push({ message: e.response.data.detail });
                                }
                                else {                                    
                                    self.errors.push(e)
                                }
                            }
                            else {                                
                                self.errors.push(e)
                            }
                            self.isLoading = false;
                        });

                }
                else {
                    
                    axios.put('/api/v1/Account/' + self.UtenteSel.id , self.UtenteSel)
                        .then(resp => {
                            if (resp.data.result) {                                
                                self.GetUtenti();
                                // Hide the modal manually
                                self.$nextTick(() => {
                                    self.resetModal();
                                    self.$bvModal.hide("modal-edit")
                                })
                            }
                            else {
                                alert("Utente non salvato");
                            }
                        })
                        .catch(e => {
                            if (e.response) {                                
                                if (Object.prototype.hasOwnProperty.call(e.response.data, 'isError')) {                                
                                    self.errors.push({ message: e.response.data.detail });
                                }
                                else {                                    
                                    self.errors.push(e)
                                }
                            }
                            else {                                
                                self.errors.push(e)
                            }
                            self.isLoading = false;
                        });
                }
            },

            OpenModal(Utente){
                this.UtenteSel = {
                    id: Utente.id,
                    name: Utente.name,
                    identitySource: Utente.identitySource,
                    swiDom: (Utente.identitySource == 1),
                    swiAmm: Utente.swiAmm,
                    swiRv: Utente.swiRv,
                    swiAream: Utente.swiAream,
                    swiAge: Utente.swiAge,
                    codaream: Utente.codaream,
                    codage: Utente.codage
                };                             
                this.$bvModal.show('modal-edit');
            },
            NuovoUtente(){
                 this.UtenteSel={
                     id: 0,
                     name: '',
                     identitySource: 0,
                     swiDom : false,
                     swiAmm: false,
                     swiRv: false,
                     swiAream: false,
                     swiAge: false,
                     codaream: '',
                     codage: ''
                };
                this.isNew=true;
            },
            Delete() {

                if (this.isNew) {
                    this.Close();
                    return;
                }
                if (!this.SwiDel) {
                    this.SwiDel = true;
                    return;
                }
                else {
                    let self = this;
                    axios.delete('api/v1/Account/' + self.UtenteSel.id)
                        .then(resp => {
                            if (!resp.data.result) {
                                self.errors.push({ message: resp.data.errors.message });
                            }
                            this.Close();
                            this.GetUtenti();
                        })
                        .catch(e => {
                            if (e.response) {
                                if (Object.prototype.hasOwnProperty.call(e.response.data, 'isError')) {
                                    self.errors.push({ message: e.response.data.detail });
                                }
                                else {
                                    self.errors.push(e)
                                }
                            }
                            else {
                                self.errors.push(e)
                            }
                        }
                        );
                }
                
            }
        },
        mounted() {            
            if(!this.$store.state.LoggedIn){
                this.$router.push('/');
            }
            this.GetUtenti();
        },
        updated() {
            
        }

        
    }


</script>