import axios from 'axios';

class UploadFilesService {
    upload(link, file, jsonParams, token, onUploadProgress) {
        let formData = new FormData();

        console.log('UploadFilesService');
        console.log(jsonParams);

        console.log('UploadFilesService 2');
        console.log(JSON.stringify(jsonParams));

        
        formData.append("jsonParams", JSON.stringify(jsonParams));
        formData.append("files", file);

        //var files = [];
        //files.push(file);

        //return axios.post(link, files, {
        return axios.post(link, formData, {        
            headers: {
                Authorization: 'Analytic ' + token ,
                "Content-Type": 'multipart/form-data'
            },
            onUploadProgress
        });
    }

    getFiles() {
        return axios.get("/files");
    }
}

export default new UploadFilesService();