<template>
    <div class="mt-2">
        <!-- Home, componente vuoto -->

        <h1><img width="64" height="64" src="./assets/database-icon.png" />Data Manager</h1>


        <Login></Login>
    </div>
</template>

<script>    
    import Login from './Login.vue';
    export default {
        components: {
            Login
        },
        mounted() {                          
        },
        
    }
</script>
