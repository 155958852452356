<template>
    <div>
        <!-- Errori -->
        <b-alert v-if="errors && errors.length" variant="danger" show dismissible>
            <ul>
                <li v-for="error of errors" :key="error.id">
                    Errore : {{error.message}}
                </li>
            </ul>
        </b-alert>
        <b-navbar v-if="this.$store.state.LoggedIn" toggleable="lg" type="dark" variant="dark">

            <b-navbar-brand href="/home/index">Data Manager</b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>

                <b-navbar-nav v-for="Folder in this.$store.state.EntityFolders" :key="Folder.id">
                    <b-nav-item-dropdown v-show="ShowMenu(Folder.id)" left :text="Folder.label">
                        <b-dropdown-item v-for="SubItem in GetSubMenu(Folder.id)" :key="SubItem.name" :to="{ path: 'ListAna', query: { rifentity: SubItem.id }} ">
                            {{SubItem.label}}
                        </b-dropdown-item>





                    </b-nav-item-dropdown>
                </b-navbar-nav>




                <!-- Jobs -->
                <b-navbar-nav>
                    <b-nav-item>
                        <router-link :to="{ path: 'ListJobs'}" class="nav-link">
                            Jobs
                            <b-badge v-show="ActiveJobs != 0">
                                {{this.ActiveJobs}}
                            </b-badge>
                        </router-link>
                    </b-nav-item>
                </b-navbar-nav>

                <!-- File Imports -->
                <b-navbar-nav>
                    <b-nav-item> <router-link :to="{ path: 'ListImports'}" class="nav-link">File Imports</router-link></b-nav-item>
                </b-navbar-nav>

                <!-- Plugins -->
                <b-navbar-nav>
                    <b-nav-item-dropdown left text="Plugins">
                        <b-dropdown-item v-for="Plugin in GetPlugins()" :key="Plugin.pluginName" :href="GetPluginLink(Plugin)">
                            {{Plugin.pluginName}}
                        </b-dropdown-item>


                    </b-nav-item-dropdown>
                </b-navbar-nav>
                


                <b-navbar-nav class="ml-auto">

                    <b-nav-item-dropdown :text="this.$store.state.Company.label" right>
                        <b-dropdown-item-button v-for="item in this.$store.state.Companies" :key="item.id" @click="SetCompany(item.id)">
                            {{item.label}}
                        </b-dropdown-item-button>
                    </b-nav-item-dropdown>

                    <b-nav-item-dropdown :text="this.$store.state.Username" right>
                        <b-dropdown-item-button v-if="1 == 2" @click="GoTo('Users')">
                            Users
                        </b-dropdown-item-button>
                        <b-dropdown-item-button v-if="this.$store.state.AllowPasswordChange" @click="ChangePassword">
                            Change Password
                        </b-dropdown-item-button>
                        <b-dropdown-item-button @click="Logout">
                            Logout
                        </b-dropdown-item-button>
                    </b-nav-item-dropdown>


                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
        
    </div>

    
</template>
<style>
    .dropdown-toggle {
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 14px;
    }

    .navbar-brand {
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        color: #5e5e5e;
    }

    .b-nav-dropdown {
        padding: 0px 5px;
    }
</style>

<script>

    


    export default {        
        props: {
            LoggedIn: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                errors: [],
                Company: {},                
                RMenuList: {},
                isLoading: false,
                BASE_URL: '',
                timer: ''
            };
        },
        computed: {
            ActiveJobs: function () {
                if (this.$store.state.SchedulerJobs.length > 0) {
                    return this.$store.state.SchedulerJobs.filter(e => e.fkstatus <= 150).length;
                }
                else {
                    return 0;
                }
            },
        },
        watch: {
            LoggedIn: {
                immediate: true,
                deep: true,
                handler(newVal) {
                    if (newVal) {                        
                        this.timer = setInterval(this.RefreshSchedJobs, 60000);
                    }
                    else {
                        clearInterval(this.timer);
                    }
                    
                }
            }
        },
        beforeDestroy() {
            clearInterval(this.timer);
        },
        methods: {
            ChangePassword() {
                console.log('ChangePassword');
                if (this.$route.path !== '/ChangePassword') this.$router.push({ path: '/ChangePassword' });
            },
            SetCompany(parCompanyId) {     
                this.$store.dispatch('SetCurrentCompany', parCompanyId);                
                if (this.$route.path !== '/*') this.$router.push({ path: '*' });
            },
            Logout() {
                
                console.log('logout');
                                
                console.log(this.$cookies.isKey('aydm'));
                if (this.$cookies.isKey('aydm')) {
                    this.$store.commit('SetLogout', this.$cookies.get('aydm').Token);
                    this.$cookies.remove('aydm');
                }
                else {
                    this.$store.commit('SetToken', '');
                    this.$store.commit('SetUsername', '');
                    this.$store.commit('SetLoggedIn', false);
                }
                
                
                if (this.$route.path !== '/*') this.$router.push({ path: '*' });

            },
            GetMenuLink(MenuItem) {
                //console.log(MenuItem.classname);
                var tPar = "";
                if (MenuItem.RouteValues) {
                    if (MenuItem.RouteValues.id) {
                        tPar = '/' + MenuItem.RouteValues.id;
                    }
                }
                tPar = MenuItem.classname;
                return 'ListAna/' + tPar;
                //return '/' + MenuItem.Controller + '/' + MenuItem.Action + tPar;
            },
            GetPluginLink(Plugin) {
                //console.log('GetPluginLink');
                return '/plugins/' + Plugin.staticContent;
            },
            ShowMenu: function (RifFolder) {
                var tNum = this.$store.state.Entity.filter(e => e.fkmanagedentityfolder == RifFolder).length;
                return (tNum > 0);
            },
            GetPlugins: function () {
                var tPlugins = this.$store.state.Plugins.filter(e => e.pluginName != 'Main');
                return tPlugins;
            },
            GetSubMenu: function (RifFolder) {                
                var tSubMenu = this.$store.state.Entity.filter(e => e.fkmanagedentityfolder == RifFolder);
                return tSubMenu;
            },
            GoTo: function (pagename) {
                let tPage = '/' + pagename;
                if (this.$route.path !== tPage) this.$router.push({ path: tPage });
            },
            RefreshSchedJobs() {
                console.log('RefreshSchedJobs');
                this.$store.commit('SetScheduledJobs');                        
            },
        },

        mounted() {


        }
    }


</script>