<template id="AyListSelect">

    <div>
        <!-- Errori -->
        <b-alert v-if="errors && errors.length" variant="danger" show dismissible>
            <ul>
                <li v-for="error of errors" :key="error.id">
                    Errore : {{error.message}}
                </li>
            </ul>
        </b-alert>
        <p v-if="isLoading">Loading...</p>        
        <model-list-select v-if="!isLoading"
                           :list="PartialList"
                           :isDisabled=false
                           v-model="InnerValue"
                           option-value="code"
                           option-text="desc"
                           placeholder="Select..."
                           @input="OnInput"
                           @searchchange="SearchChange2">
        </model-list-select>
    </div>
    
</template>

<script>    
    import axios from 'axios';    
    import { ModelListSelect } from 'vue-search-select';
    export default {
        name: 'AyListSelect',
        props: {
            value: {
                //type: Number,
                required: true
            },
            filters: {
                type: Array,
                required: false
            },
            reference: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                awaitingSearch: false,
                errors: [], 
                InnerValue: Object,
                LastQuery: 0,
                PartialList: [],
                InnerFilters : [],
                RifEl: Object,
                RifEnt: Object,
                isLoading: true,
                isBusy: false,
                search: '',
            };
        },
        components: {
            ModelListSelect
        },
        watch: {
            reference: {
                immediate: true,
                handler(newVal) {
                    console.log('referencev' + newVal);
                    this.UpdateData();                 
                }
            },
            value: {
                immediate: true,
                handler(newVal) {     
                    console.log('value cambiato');                    
                    this.InnerValue = newVal;          
                    this.UpdateData();

                         
                }
            },
            filters: function () {

                if ((this.InnerFilters.length > 0) && (this.filters.length == 0)) {       
                    // Ho rimosso i filtri, devo aggiornare la lista locale
                    this.InnerFilters = [];    
                    setTimeout(() => {
                        this.GetData2();
                        return;
                    }, 1000); 
                    
                    return;
                }

                if (this.filters == this.InnerFilters) {                    
                    console.log('filtro su reference : ' + this.reference);
                    console.log('filtro uguale');
                    return;
                }
                
                this.InnerFilters = [];
                if (this.filters.length > 0) {
                    console.log('filtro su reference : ' + this.reference);
                    //console.log(this.RifEnt.label);
                    //console.log('filters changed');
                    //console.log(this.filters);

                    this.InnerFilters = [];
                    this.filters.forEach(e => {
                        var tFilter = {};                    
                        tFilter.managedEntityElement = e.managedEntityElement;
                        tFilter.inverse = e.inverse;
                        tFilter.bigintValues = e.bigintValues;
                        tFilter.nvarcharValues = e.nvarcharValues;
                        this.InnerFilters.push(tFilter);
                    });
                    setTimeout(() => {
                        this.GetData2();
                    }, 1000); 
                }

            },
            search: function () {
                console.log(this.search);                
                //debounce...
                if (!this.awaitingSearch) {
                    setTimeout(() => {
                        if ((!this.isBusy) && (this.search != '' )) {
                            this.GetData2();
                        }
                        this.awaitingSearch = false;
                    }, 700); // 0,5 sec delay

                }

                this.awaitingSearch = true;
            }

        },

        methods: {
            GetComboArray: function (SourceArray) {                          
                // Trasformo l'array ottenuto per avere un elenco di codici - descrizioni (che sono la somma ti tutti i campi visibili)                            
                var tElements = this.$store.state.ElementsAll.filter(E => E.fkmanagedentity === this.RifEl.fkmanagedentity);
                
                var tKeyField = this.RifEl.id;

                var output = [];
                //console.log('KeyField :' + KeyField);                
                var tKeyName = tElements.find(E => E.id == tKeyField).csfield.toLowerCase();
                var tElDesc = tElements.filter(E => E.insummary == true);
                for (var i = 0; i < SourceArray.length; ++i) {
                    var tDesc = [];

                    tElDesc.forEach(element => {
                        //console.log(element.csfield);
                        tDesc.push(SourceArray[i][element.csfield.toLowerCase()]);
                    });

                    var tRow = {};
                    tRow.code = SourceArray[i][tKeyName];
                    tRow.desc = tDesc.join(" | ");
                    output.push(tRow);
                }
                //console.log(output);
                return output;
            },
            GetData2() {                
                this.PartialList = [];
                //console.log('GetData2');
                this.errors = [];
                var tReqBody = {};
                //Da modificare, deve scaricare il valore correlato con il valore selezionato oltre che la lista
                let self = this;     
                //var Link = 'api/' + ((this.RifEnt.fkcompany == -1 || this.RifEnt.fkcompany == 0) ? '_main' : this.$store.state.Company.csnamespace) + '/' + this.RifEnt.csclass + '/summary/filtered';
                var Link = 'api/' + this.RifEnt.controller + '/summary/filtered';
                
                if (this.search != '') {
                    console.log('GetData2 search :' + this.search);
                    //Link = 'api/' + ((this.RifEnt.fkcompany == -1 || this.RifEnt.fkcompany == 0) ? '_main' : this.$store.state.Company.csnamespace) + '/' + this.RifEnt.csclass + '/summary/filtered';
                    
                    tReqBody.search = this.search;
                }
       
                self.isBusy = true;
                
                // Body                
                //Non voglio la paginazione, posso togliere i parametri ?
                tReqBody.pagination = {};
                tReqBody.pagination.pageNumber = 1;
                tReqBody.pagination.pageSize = 100;
                tReqBody.pagination.includeCount = false;
                if (this.InnerFilters.length > 0) {
                    tReqBody.filters = this.InnerFilters;
                }
                //tReqBody.filters = Filters;
                //console.log(tReqBody);
                self.LastQuery = Date.now(); 
                axios.post(Link, tReqBody, this.$store.state.Headers)                
                    .then(response => {                        
                        var tArr = this.GetComboArray(response.data.Result);
                        if (tArr.length > 0) {
                            self.PartialList = this.GetComboArray(response.data.Result);                            
                        }
                        else {
                            self.isBusy = false;                            
                            return;
                        }                        
                                                
                        //Se non c'� il record lo richiedo a parte
                        //var tTime = Date.now();                        
                        var tWait = Date.now() - this.LastQuery;
                                               

                        if (tWait > 500) {
                            console.log('No Wait: ' + tWait);
                            this.GetDataSelValue();
                        }
                        else {
                            //console.log('wait : ' + tWait);
                            setTimeout(() => {
                                this.GetDataSelValue();
                            }, tWait); // delay
                        }
                                                

        

                    }).catch(e => {
                        this.errors.push(e)
                        console.log(e);
                    });
            },
            GetDataSelValue() {
                //Richiedo il valore selezioanto e lo aggiungo alla lista
                var tReqBody = {};
                tReqBody.pagination = {};
                tReqBody.pagination.pageSize = 1;                
                tReqBody.search = undefined;
                let self = this;
                //var Link = 'api/' + ((this.RifEnt.fkcompany == -1 || this.RifEnt.fkcompany == 0) ? '_main' : this.$store.state.Company.csnamespace) + '/' + this.RifEnt.csclass + '/summary/filtered';
                var Link = 'api/' + this.RifEnt.controller + '/summary/filtered';

                //Filter
                var Filter = {};
                //var Filters = self.InnerFilters;
                var Filters = self.InnerFilters.slice();
                Filter.managedEntityElement = this.reference;
                Filter.inverse = false;
                Filter.bigintValues = [];
                Filter.nvarcharValues = [];
                //console.log('filtro Value : ' + this.value);
                var tSqltype = this.RifEl.sqltype;
                if ((this.value != undefined) && (this.value != null)) {
                    if ((tSqltype == 'varchar') || (tSqltype == 'nvarchar')) {
                        //console.log('aggiunto a nvarchar');
                        Filter.nvarcharValues.push(this.value);
                    }
                    else {
                        //console.log('aggiunto a bigint');
                        Filter.bigintValues.push(this.value);
                    }
                    Filters.push(Filter);
                }
                else {
                    //console.log('ignorato filtro Value : ' + this.value);
                }
                tReqBody.filters = Filters;

                if (this.InnerValue != null) {
                    //Verifico che il valore selezionato sia in elenco, altrimenti lo richiedo e lo aggiungo alla lista
                    //console.log('this.InnerValue : ' + this.InnerValue);
                    
                    if (!this.PartialList.find(el => el['code'] == this.InnerValue)) {
                        axios.post(Link, tReqBody, this.$store.state.Headers)
                            .then(response => {
                                var tRec = this.GetComboArray(response.data.Result);
                                //console.log('tRec.length :' + tRec.length);
                                if (tRec.length > 0) {
                                    self.PartialList.push(tRec[0]);
                                }
                                else {
                                    this.InnerValue = undefined;
                                }

                                self.isLoading = false;

                                //Elimino la selezione se non c'� l'elemento in lista
                                if (this.InnerValue) {
                                    if (!self.PartialList.find(e => e['code'] == this.InnerValue)) {                                        
                                        this.InnerValue = undefined;
                                        this.OnInput(undefined);
                                    }
                                    else {
                                        this.search = '';
                                    }
                                }
                                self.isBusy = false;
                                self.LastQuery = Date.now(); 
                            }).catch(e => {
                                this.errors.push(e)
                                console.log(e);
                            });
                    }
                    else {
                        self.isBusy = false;
                        self.isLoading = false;
                        self.LastQuery = Date.now(); 
                    }
                }
                else {
                    self.isLoading = false;
                    self.isBusy = false;
                    self.LastQuery = Date.now(); 
                }
            }, 
            ViewList() {

                var elements = document.getElementsByClassName('bitprop')

                for (var i = 0; i < elements.length; i++) {
                    elements[i].style.display = 'none';
                }

                this.SwiList = !this.SwiList;
            },
            OnInput(newvalue) {
                //console.log('OnInput personalizzato ' + newvalue);
                if (this.value == newvalue) {
                    //console.log('OnInput ignorato sono uguali');
                    return;
                }
                this.$emit('input', newvalue);
                this.$emit('change', newvalue);
            },
            SearchChange2(searchtext) {                
                this.errors = [];
                if (searchtext.length > 1) {
                    this.search = searchtext;
                    //lo chiama il watch
                    //this.GetData2();
                }
                
            },
            UpdateData() {
                this.isLoading = true;
                this.isBusy = false;                
                this.search = '';
                this.errors = [];

                this.RifEl = this.$store.state.ElementsAll.find(E => E.id === this.reference);
                this.RifEnt = this.$store.state.Entity.find(E => E.id === this.RifEl.fkmanagedentity);

                this.GetData2();            

            }


            
        },
        mounted() {
            //console.log('mounted ay-list-select');
        },
        updated() {
            //console.log(this.$route)
        }


    }


</script>