import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import BootstrapVue from 'bootstrap-vue';
import moment from 'moment';

// Componenti del progetto
import Home from './Home.vue';
import ListAna from './ListAna.vue';
import ListJobs from './ListJobs.vue';
import ListImports from './ListImports.vue';
import ChangePassword from './ChangePassword.vue';
import ListUsers from './ListUsers.vue';


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import 'vue-search-select/dist/VueSearchSelect.css'

import axios from 'axios';
import VueCookies from 'vue-cookies'




library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.use(BootstrapVue);
Vue.use(moment);
// Imposta i routes
Vue.use(VueRouter);
const router = new VueRouter();
// Vuex
Vue.use(Vuex)
// Cokies
Vue.use(VueCookies)

const store = new Vuex.Store({
    state: {
        AllowPasswordChange: true,
        errors: [],
        Companies: {},
        Entity: [],
        EntityFolders: {},
        Elements: {},
        ElementsAll: [],
        FileLoaderLayouts: {},
        isLoading: false,
        Jobs: [],
        JobStatusTypes: [],
        PageSize: 100,
        Plugins: [],
        SchedulerJobs: [],
        Company: {},
        Token: '',
        Headers: {},
        Started: false,
        LoggedIn: false,
        Username: '',
        baseUrl: '',
        VisibileColumns: {}
    },
    mutations: {
        IsStarted: state => state.Started = true,
        SetAllowPasswordChange(state, parAllow) {
            state.AllowPasswordChange = parAllow;
        },
        SetToken(state, parToken) {
            state.Token = parToken;

            const config = {
                headers: { Authorization: 'Analytic ' + parToken }
            };
            state.Headers = config;
        },
        SetIsLoading(state, parIsLoading) {
            state.isLoading = parIsLoading;
        },
        SetLoginParams(state, params) {
            state.Token = params.Token;
            state.Username = params.Username;
            state.LoggedIn = true;
            // Il cookie non posso impostarlo dalla mutation...

            const config = {
                headers: { Authorization: 'Analytic ' + state.Token }
            };
            state.Headers = config;


        },
        SetLogout(state, parToken) {

            const tConfig = {
                headers: { Authorization: 'Analytic ' + parToken }
            };
            state.Headers = tConfig;
            console.log('headers : ' + state.Headers);
            let self = this;
            self.isLoading = true;

            self.errors = [];
            var Link = 'api/_main/Account/logout';
            axios.get(Link, state.Headers)
                .then(() => {
                    state.Token = '';
                    state.Username = '';
                    state.LoggedIn = false;
                    self.isLoading = false;
                }).catch(e => {
                    state.errors.push(e);
                    state.Token = '';
                    state.Username = '';
                    state.LoggedIn = false;
                    self.isLoading = false;
                });
            //state.Token = '';
            //state.Username = '';
            //state.LoggedIn = false;
            //self.isLoading = false;

            const config = {
                headers: { Authorization: 'Analytic ' + state.Token }
            };
            state.Headers = config;



        },
        SetPageSize(state, parPageSize) {
            state.PageSize = parPageSize;
        },
        SetUsername(state, parUsername) {
            state.Username = parUsername;
        },
        SetCompanyUrl(state, parCompanyUrl) {
            state.CompanyUrl = parCompanyUrl;
        },
        SetEntity(state, parEntity) {
            state.Entity = parEntity;
        },
        SetElements(state, parElements) {
            state.Elements[parElements[0].fkmanagedentity] = parElements;
        },
        SetBaseUrl(state, parBaseUrl) {
            state.baseUrl = parBaseUrl;
        },
        SetLoggedIn(state, parLoggedIn) {
            state.LoggedIn = parLoggedIn;
        },
        SetCompanies(state, parCompanies) {
            state.Companies = parCompanies;
            state.Companies.filter(i => i.id == 0).forEach(i => i.csnamespace = '_main');
        },
        SetCompany(state) {
            //console.log('SetCompany');
            let self = this;
            state.isLoading = true;

            state.FileLoaderLayouts = [];
            state.Templates = [];

            self.BASE_URL = self.baseUrl == '/' ? '' : self.baseUrl;

            axios.defaults.baseURL = self.baseUrl;

            axios.all([
                // Scarico le Entity per la Company selezionata
                axios.get('api/_main/ManagedEntity', state.Headers),
                // Scarico tutti gli Elements
                axios.get('api/_main/ManagedEntityElement', state.Headers),
                // Scarico ELenco Job 
                axios.get('api/_main/SchedulerJobTemplate', state.Headers),
                //Scarico Job Status List
                axios.get('api/_main/SchedulerJobStatus', state.Headers),
                // File Import List
                axios.get('api/_main/FileLoaderLayout', state.Headers),
                // Entity Folders
                axios.get('api/_main/ManagedEntityFolders', state.Headers),
                //Scheduler Jobs
                axios.get('api/_main/SchedulerJob', state.Headers),
                //Plugin List
                axios.get('api/_main/Plugins/list', state.Headers),
            ])
                .then(axios.spread((...responses) => {
                    // Entity per la Company selezionata
                    state.Entity = responses[0].data.Result;
                    // Elements per la Company selezionata
                    state.ElementsAll = responses[1].data.Result;
                    // Elenco Job 
                    state.Jobs = responses[2].data.Result;
                    //Scarico Job Status List
                    state.JobStatusTypes = responses[3].data.Result;
                    // File Import List
                    state.FileLoaderLayouts = responses[4].data.Result;
                    // Entity Folders
                    state.EntityFolders = responses[5].data.Result;
                    //Scheduler Jobs
                    state.SchedulerJobs = responses[6].data.Result;
                    //Plugin List
                    state.Plugins = responses[7].data.Result;
                    state.isLoading = false;
                })).catch(e => {
                    state.errors.push(e)
                });



        },
        SetError(state, parError) {
            state.errors.push(parError);
        },
        SetScheduledJobs(state) {
            axios.get('api/_main/SchedulerJob', state.Headers)
                .then(response => {
                    state.SchedulerJobs = response.data.Result;
                }).catch(e => {
                    state.errors.push(e)
                });
        },
        // SetVisibileColumns(state, rifEntity, columns) {            
        //     //Non completo
        //     state.VisibileColumns
        // },
  

    },
    actions: {
        async GetCompanies(context) {
            console.log('GetCompanies');
            let self = this;
            context.commit('SetIsLoading', true);
            //self.BASE_URL = self.baseUrl == '/' ? '' : self.baseUrl;

            axios.defaults.baseURL = self.baseUrl;

            var Link = 'api/_main/Company';
            axios.get(Link, context.state.Headers)
                .then(response => {                    
                    context.commit('SetCompanies', response.data.Result);
                    context.dispatch('GetCurrentCompany').then(() => {
                        context.commit('SetCompany');
                    })
                    context.commit('SetIsLoading', false);
                }).catch(e => {
                    context.commit('SetError', e);
                });
        },
        async GetCurrentCompany(context) {            
            // Imposto l'azienda corrente
            var Link = 'api/_main/Account/selectedcompany';            
            axios.get(Link, context.state.Headers)
                .then(response => {
                    var tCompanyID = response.data.Result;                    
                    context.state.Company = context.state.Companies.find(e => e.id == tCompanyID);
                }).catch(e => {
                    context.commit('SetError', e);
                });
        },
        async SetCurrentCompany(context, parCompanyId) {
            console.log('SetCurrentCompany : ' + parCompanyId);
            // Imposto l'azienda corrente
            var Link = 'api/_main/Account/selectedcompany';
            let data = {
                company: parCompanyId
            }
            axios.post(Link, data, context.state.Headers)
                .then(response => {                    
                    if (response.data.Result) {
                        context.state.Company = context.state.Companies.find(e => e.id == parCompanyId);
                        context.commit('SetCompany');            
                    }                    
                }).catch(e => {
                    context.commit('SetError', e);
                });
        },
    }
})


new Vue({
    router,
    store,
    mounted() {
        router.addRoutes(
            [                
                { path: '/ListAna', component: ListAna, name: "ListAna", props: (route) => ({ rifentity: Number(route.query.rifentity) }) },
                { path: '/ListJobs', component: ListJobs, name: "ListJobs" },
                { path: '/ListImports', component: ListImports, name: "ListImports" },
                { path: '/ChangePassword', component: ChangePassword, name: "ChangePassword" },
                { path: '/Users', component: ListUsers, name: "Users" },
                { path: '*', component: Home } // catch all redirect
            ]
        );
        //this.$router.listen((newLocation) => { console.log(newLocation); })
    },
  render: h => h(App),
}).$mount('#app')
