<template id="Login">
    <div>

        <!-- Errori -->
        <b-alert v-if="errors && errors.length" variant="danger" show dismissible>
            <ul>
                <li v-for="error of errors" :key="error.id">
                    Errore : {{error.message}}
                </li>
            </ul>
        </b-alert>

                <!--Spinner-->
        <div v-if="this.$store.state.isLoading" id="div-spinner" style="position:fixed;top:50%;left:50%">
            <b-spinner variant="primary" style="width: 3rem; height: 3rem;" label="$t('messages.RicercaInCorso')"></b-spinner>            
        </div>
        

        <b-container v-if="!this.$store.state.LoggedIn" class="bv-example-row">

            <b-row align-h="center">
                <b-col cols="6">
                    <!-- Card -->
                    <b-card 
                            body-class="text-center"
                            header-tag="nav"
                            style="max-width: 30rem;"
                            class="mb-2">

                        <b-input-group class="mt-3">
                            <template v-slot:prepend>
                                <font-awesome-icon size="2x" icon="user" transform="shrink-1 left-6" />

                            </template>
                            <b-form-input id="fgi_username"
                                          v-model="Username"
                                          placeholder="Username"
                                          type="text">
                            </b-form-input>
                        </b-input-group>

                        <b-form-group>
                            <b-input-group class="mt-3">
                                <template v-slot:prepend>
                                    <font-awesome-icon size="2x" icon="lock" transform="shrink-1 left-6" />

                                </template>
                                <b-form-input id="fgi_Password"
                                              v-model="Password"
                                              placeholder="Password"
                                              type="password">
                                </b-form-input>
                            </b-input-group>
                            
                        </b-form-group>
                        <b-card-text class="text-danger">{{LoginErrorResponse}}</b-card-text>                        
                        <div class="text-center py-4 mt-3">
                            <b-button variant="success" @click="Login">LOGIN</b-button>
                        </div>

                    </b-card>
                    <!-- Card -->
                </b-col>
            </b-row>
        </b-container>
    </div>        

</template>


<script>        
    import axios from 'axios';
    

    export default {  
        name: 'Login',
        data() {
            return {
                errors: [],  
                isLoading: false,
                Username: '',
                Password: '',
                LoginErrorResponse: ''
            };
        },
        components: {            
        },
        watch: {            
          
        },
        methods: {
            Login() {
                let self = this;
                self.isLoading = true;
                this.$store.commit('SetLoggedIn', false);      
                self.errors = [];
                var Link = 'api/_main/Account/login';
                let data = {
                    username: this.Username,
                    password: this.Password
                }
                axios.post(Link, data)
                    .then(response => {       
                        self.errors = [];
                        self.LoginErrorResponse = '';
                        var tParams = {};
                        tParams.Token = response.data.Result.id;
                        tParams.Username = this.Username;
                        this.$store.commit('SetLoginParams', tParams);
                        this.$store.commit('SetAllowPasswordChange', response.data.Result.allowPasswordChange);
                        

                        this.$store.dispatch('GetCompanies');

                        //Cookie
                        this.$cookies.config('30d');
                        this.$cookies.set('aydm', tParams);
                                                
                        self.errors = this.$store.state.errors;
                        self.isLoading = false;
                        self.Username = '';
                        self.Password = '';
                        console.log('fine login');
                        
                        
                    })
                    .catch(e => {  
                        self.isLoading = false;
                        if (e.response) {
                            if (Object.prototype.hasOwnProperty.call(e.response.data, 'isError')) {
                                self.LoginErrorResponse = e.response.data.title;
                            }
                            else {
                                this.errors.push(e)
                            }
                            this.$store.commit('SetLoggedIn', false);
                        }
                    })

            }
    
     
        },
        mounted() {
            console.log('mounted');
        },
        updated() {            
        }

        
    }


</script>