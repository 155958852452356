<template id="ListAna">
    <div class="list-ana" v-if="this.$store.state.LoggedIn">   
        <div class="list-ana-head">
            <b-navbar toggleable="lg" type="dark" variant="dark">
                <b-navbar-nav>

                    <b-nav-form>
                        <b-form-input placeholder="Search" v-model="search" class="mr-sm-2 search-query st-default-search-input aa-input"></b-form-input>
                    </b-nav-form>

                    <b-nav-item-dropdown :text="'Rows : ' + (this.$store.state.PageSize !== 0 ? this.$store.state.PageSize.toString() : 'All') + ' (Tot. ' + TotalRecords +')' " right>
                        <b-dropdown-item @click="SetPageSize(50)" href="#">50</b-dropdown-item>
                        <b-dropdown-item @click="SetPageSize(100)" href="#">100</b-dropdown-item>
                        <b-dropdown-item @click="SetPageSize(200)" href="#">200</b-dropdown-item>
                        <b-dropdown-item @click="SetPageSize(500)" href="#">500</b-dropdown-item>
                        <b-dropdown-item @click="SetPageSize(1000)" href="#">1000</b-dropdown-item>
                        <b-dropdown-item @click="SetPageSize(0)" href="#">All</b-dropdown-item>
                    </b-nav-item-dropdown>
                    <b-button title="Export Excel" v-if="datatable.length > 0 && !isModal" pill variant="outline-success" style="margin-left: 12px;" align="right" @click="ExportFile('XSLX')"><font-awesome-icon size="sm" icon="file-excel" /></b-button>
                    <b-button title="Export CSV" v-if="datatable.length > 0 && !isModal" pill variant="outline-success" style="margin-left: 12px;" align="right" @click="ExportFile('CSV')"><font-awesome-icon size="sm" icon="file-csv" /></b-button>

                    <b-button title="Columns Selection" v-if="VisibleFields.length > 0 && !isModal" pill variant="outline-primary" style="margin-left: 12px;" align="right" @click="SwiSelectColumns=true"><font-awesome-icon size="sm" icon="list" /></b-button>

                    <b-button title="Select Item" v-if="SelectedRows.length > 0 && isModal" variant="outline-success" style="margin-left: 12px;" @click="ModalRowSelected()"><font-awesome-icon size="sm" icon="plus" /> Select</b-button>
                </b-navbar-nav>

                <b-navbar-nav class="ml-auto" v-if="!isModal">

                    <div v-if="SwiUpdate">
                        <b-nav-text><b>{{this.$store.state.Jobs.find(E => E.id === this.RifJob).description}}</b></b-nav-text>

                    </div>
                    

                    <b-button title="Run Job" v-if="SwiUpdate" variant="outline-success" style="margin-left: 12px;" @click="RunJob()"><font-awesome-icon size="sm" icon="play" /></b-button>

                    <b-button title="Add new record" pill variant="outline-success" style="margin-left: 12px;" align="right" @click="OpenEdit(false)"><font-awesome-icon size="sm" icon="plus" /></b-button>

                    <b-button title="Multi Edit" v-if="datatable.length > 0" pill variant="outline-success" style="margin-left: 12px;" align="right" @click="OpenEdit(true)"><font-awesome-icon size="sm" icon="clone" /></b-button>

                    <b-button title="Delete Selected" v-if="SelectedRows.length > 0" pill variant="outline-danger" style="margin-left: 12px;" align="right" @click="DeleteSelected(true)"><font-awesome-icon size="sm" icon="minus" /></b-button>


                    <b-button id="remove_col_filters" title="Clear all column filters" pill v-if="AllFilters.length > Filters.length" variant="outline-primary" style="margin-left: 12px;" @click="RemoveFilters">
                        <font-awesome-icon size="sm" icon="filter" style="color:dodgerblue" />
                        <font-awesome-icon size="xs" icon="minus-circle" transform="shrink-4 down-3 left-6" />
                    </b-button>


                </b-navbar-nav>


            </b-navbar>


            <!-- Errori -->
            <b-alert v-if="errors && errors.length" variant="danger" show dismissible>
                <ul>
                    <li v-for="error of errors" :key="error.id">
                        Errore : {{error.message}}
                    </li>
                </ul>
            </b-alert>



            <h2 v-if="SelectedEntity && !isModal">{{SelectedEntity.label}}</h2>

            <div class="row">
                <!-- Filtri -->
                <FilterAna v-if="HasFilters" id="edit1" :New="SwiNew" :Rifentity="rifentity" v-on:filter="OnSetFilters"></FilterAna>
                <b-card-group columns v-if="RifJobExec != 0">
                    <JobStatusCard :rifjob="RifJobExec"></JobStatusCard>
                </b-card-group>
            </div>
        
            <!--Spinner-->
            <div v-if="isLoading" id="div-spinner" style="position:fixed;top:50%;left:50%">
                <b-spinner variant="primary" style="width: 3rem; height: 3rem;" label="$t('messages.RicercaInCorso')"></b-spinner>
            </div>
            <div v-else>


                <!-- Multi Edit -->
                <div v-for="(window,index) of Windows" :key="`edit-${index}`">
                    <EditAna :id="window.id" :New="window.SwiNew" :Clone="window.SwiClone" :Multi="window.SwiMulti" :Rifentity="window.RifEntity" :Row="window.Row" :TemplateFilters="window.TemplateFilters" :RowId="window.SelectedRowId" v-on:hidemodal="onHideEdit" v-on:newcomboelement="onNewComboElement"></EditAna>
                </div>

                <!-- Multi Delete -->
                <div v-if="SwiDeleteAskConfirm" class="msgbox">

                    <div class="msgbox-content text-center rounded">
                        <div class="msgbox-header text-left"><h4>{{SelectedEntity.label}}</h4></div>
                        <div class="d-flex py-4">
                            {{SelectedRows.length}} record selected, Do you want to DELETE ALL OF THEM ?
                        </div>
                        <div class="d-flex">
                            <b-button variant="danger" class="ml-auto" @click="DeleteSelected(false)">
                                Delete
                            </b-button>
                            <b-button class="ml-2" @click="SwiDeleteAskConfirm=false">
                                Cancel
                            </b-button>
                        </div>
                    </div>
                </div>

                <!-- Column Selection -->
                <div v-if="SwiSelectColumns" class="msgbox">
                    <div class="msgbox-content  rounded" style="width: 20%; ">
                        <div class="msgbox-header ">
                            <div class="row">
                                <div class="col-10"><b>{{SelectedEntity.label}}</b></div>
                                <div class="col-1"><b-link style="padding-right : 50px; margin-right : 50px"  @click="SwiSelectColumns=false" href="#foo"><font-awesome-icon size="lg" icon="window-close" style="color:darkgray" /></b-link></div>
                            </div>                       
                        </div>
                        <div class="text-left pl-4">
                            <draggable v-model="nonFixedFields">
                                <b-checkbox :disabled="VisibleFields.length == 1 && field.visible"
                                            v-for="field in nonFixedFields"
                                            :key="field.id"
                                            v-model="field.visible"
                                            stacked
                                            >
                                    {{ field.label }}
                                </b-checkbox>
                            </draggable>
                        </div>                  
                    </div>
                </div>
            </div> <!--v-else-->
        </div>
        
        <div class="list-ana-cont" v-if="!isLoading">
            <b-table
                        selectable
                        :select-mode="isModal?'single':'multi'"
                        class="table_Cell"
                        :items="datatable"
                        :primary-key="this.KeyPropDef.csfield"
                        :fields="VisibleFields"
                        :tbody-tr-class="RowClass"
                        responsive
                        sticky-header
                        perPage="2000"
                        no-footer-sorting
                        @row-selected="OnRowSelected">

                <!-- Default fall-back custom formatted footer cell -->
                <template v-slot:head()="data">
                    <div :id="'filter-box-' + data.column">
                        <span> {{data.label }}</span>
                        <b-button size="sm" variant="light" @click="OpenColumnFilter(data.column, $event)">
                            <div>
                                <font-awesome-icon v-if="fields.find(E => E.key === data.field.key).filters.length == 0" size="xs" icon="angle-down" fixed-width />
                                <font-awesome-icon v-if="fields.find(E => E.key === data.field.key).filters.length > 0" size="xs" icon="filter" fixed-width />
                            </div>
                        </b-button>
                        <ColumnFilter  v-if="ShowColumnFilter"
                                    v-show="SelectedColumnName == data.column"
                                    v-model="fields.find(E => E.key === data.field.key).filters"
                                    :Elements="GetFilterElements(data.column)"
                                    v-on:change="GetData()"></ColumnFilter>
                    </div>
                </template>

                <!-- A virtual composite column -->
                <template v-if="!isModal" v-slot:cell(editrec)="data" @click="Edit(data)">

                    <font-awesome-icon size="lg" icon="edit" @click="Edit(data.item)" style="color:dodgerblue" />
                </template>

                <!-- A virtual composite column -->
                <template v-slot:cell(bitprop)="data">
                    <BitProp v-model="data.item.bitprop" v-bind:ShowUndefined="false" v-on:change="SaveRow(data.item)"></BitProp>
                </template>

            </b-table>
            <b-pagination v-if="SwiPaginazione"
                            v-model="PageNumber"
                            :total-rows="TotalRecords"
                            :per-page="this.$store.state.PageSize"
                            v-on:change="ChangePage"
                            class="col col-8 col-lg-10"
                            align="center">
            </b-pagination>
        </div>

    </div>
    <div v-else>
        <Oops></Oops>        
    </div>

</template>

<style>
    .list-ana {
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }
    .list-ana-head {
        flex: 0 0 auto;
    }
    .list-ana-cont {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        align-items: center;
    }
    .list-ana-cont > .table_Cell {
        flex: 1 1 auto;
        max-height: none;
    }
    .list-ana-cont > .pagination {
        flex: 0 0 auto;
        text-align: center;
    }
    .table_Cell {
        text-align: left;
        font-size: 14px;
        font-size: 0.8rem;
        font-weight: 400;
        line-height: 1.2;
    }
    .table_Cell th > div {
        display: flex;
        align-items: flex-end;
        position: relative;
        cursor: pointer;
    }
    .table_Cell th > div > span {
        flex: 1 1 auto;
        text-overflow: hidden;
    }
    .table_Cell th > div > .list-column-filter {
        position: absolute;
        left: 0;
    }
    .search-query {
        height: 30px;
        line-height: 30px;
        box-sizing: border-box;
        padding: 0 15px 0 30px;
        border: 1px solid #e3e3e3;
        color: #273849;
        outline: none;
        border-radius: 15px;
        margin-right: 10px;
        transition: border-color 0.2s ease;
        /*background: #fff url("search.png") 8px 5px no-repeat; background-size: auto;*/
        background-size: 20px;
        vertical-align: middle !important;
    }

    .form-group {
        margin-bottom: 0.50rem;
    }

    .msgbox {
        display: block; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 999; /* Sit on top */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    }

    /* Modal Content/Box */
    .msgbox-header {        
        justify-content: space-between;     
        border-bottom: 1px solid #dee2e6;
        border-top-left-radius: calc(0.3rem - 1px);
        border-top-right-radius: calc(0.3rem - 1px);
    }

    /* Modal Content/Box */
    .msgbox-content {
        background-color: #fefefe;
        margin: 15% auto; /* 15% from the top and centered */
        padding: 20px;
        border: 1px solid #888;
        width: 40%; /* Could be more or less, depending on screen size */
    }

</style>

<script>    
    // require("/assets/search.png")
    import axios from 'axios';
    import EditAna from './EditAna.vue';
    import FilterAna from './FilterAna.vue';
    import BitProp from './BitProp.vue';
    import ColumnFilter from './ColumnFilter.vue';
    import JobStatusCard from './JobStatusCard.vue';
    import Oops from './Oops.vue';
    import moment from 'moment';
    import draggable from 'vuedraggable';
    

    export default {
        name: 'ListAna',
        props: {
            rifentity: {
                type: Number,
                required: true
            },
            isModal: {
                type: Boolean,
                required: false,
                default: false
            }            
        },
        data() {
            return {
                errors: [],  
                isLoading: true,
                SelectedEntity: Object,                                
                KeyPropDef: Object,
                datatable: [],
                datadefinition: [],
                fixedFields: [],
                nonFixedFields: [],
                Filters: [],
                AllFilters: [],
                HasFilters: false,
                HasReqFilters: false,
                RifJobExec: 0,
                search: '',
                SelectedColumnName: '',
                SwiPaginazione: true,
                PageNumber : 1,                
                Template: Object,
                TotalRecords : 1,
                TotalPages : 1,
                awaitingSearch: false,                
                SelectedRowId: {
                    type: String,
                    default: ''
                },
                SelectedRows: [],
                SwiSelectColumns: false,
                SwiDeleteAskConfirm: false,
                SwiEdit: false,
                SwiNew: false,
                ShowColumnFilter: false,
                Windows: [],
                sortBy: null,
                sortDesc: null,
            };
        },
        computed: {
            // a computed getter
            RifJob: function () {
                // Recupero eventuali Job collegati con l'entità
                if (Object.prototype.hasOwnProperty.call(this.SelectedEntity, 'fkschedulerjobtemplate')) {
                    return  this.SelectedEntity.fkschedulerjobtemplate;
                }
                else {
                    return 0;
                }
            },
            SwiUpdate: function () {
                
                if (this.RifJob > 0) {
                    //Se è stato impostato un filtro NON obbligatorio questo metodo falllisce !!!
                    if ((this.HasReqFilters) & (this.Filters.length > 0)) {
                        return true;
                    }
                    else {
                        //Se non ci sono filtri obbligatori : visualzzo
                        return !this.HasReqFilters;
                        //return false;
                    } 
                }
                else {
                    //Non c'è Job correlato
                    return false;
                }
            },
            fields() {
                return [...this.fixedFields, ...this.nonFixedFields];
            },
            VisibleFields() {
                return this.fields.filter(field => field.visible)
            }
        },
        components: {
            EditAna,
            FilterAna,
            BitProp,
            ColumnFilter,
            JobStatusCard,
            Oops,
            draggable,
        },
        watch: {                        
            rifentity: {
                immediate: true,
                deep: true,
                handler() {                    
                    this.isLoading = true;
                    this.SwiEdit = false;
                    this.SwiNew = false;
                    this.search = '';
                    this.PageNumber = 1;
                    this.Filters = [];
                    this.errors = [];
                    this.datatable = [];
                    this.ShowColumnFilter = false;
                    this.HasReqFilters = false;
                    this.RifJobExec = 0;
                    this.Windows = [];

                    if (this.$store.state.Entity.length == 0) {
                        return;
                    }
                    //console.log(this.$store.state.Entity);
                    this.SelectedEntity = this.$store.state.Entity.find(E => E.id === this.rifentity);
                    // Recupero la prop della chiave
                    var tElements = this.$store.state.ElementsAll.filter(E => E.fkmanagedentity === this.rifentity);
                    this.keypropdef = tElements.find(c => c.entitykey === true);
                                        
                    
                    this.isLoading = false;

                    
                    //Data Definition
                    let self = this;
                    self.isLoading = true;                       
                    //var Link = 'api/' + (self.SelectedEntity.fkcompany == -1 ? '_main' : this.$store.state.Company.csnamespace) + '/' + self.SelectedEntity.csclass + '/detailed/meta';                    
                    var Link = 'api/' +  self.SelectedEntity.controller + '/detailed/meta';
                    
                    axios.get(Link, this.$store.state.Headers)
                        .then(response => {
                            self.datadefinition = response.data.Result;

                            self.fixedFields = [];
                            self.nonFixedFields = [];

                            if(!self.isModal)
                            {
                                // Link per Edit
                                var tFieldEdit = {};
                                tFieldEdit.label = '';
                                tFieldEdit.key = 'editrec';
                                tFieldEdit.filters = [];
                                tFieldEdit.rifelement = 0;
                                tFieldEdit.visible = true;
                                self.fixedFields.push(tFieldEdit);
                            }
                            
                            var tNumFiltri = self.datadefinition.filter(c => c.fkentityfilterdisplay != 0).length;
                            var tNumReqFiltri = self.datadefinition.filter(c => c.fkentityfilterdisplay == 2).length;
                            //console.log('num filtri : ' + tNumFiltri);
                            //console.log('num req filtri : ' + tNumReqFiltri);
                            self.HasFilters = (tNumFiltri != 0);
                            self.HasReqFilters = (tNumReqFiltri != 0);

                            var tFields = self.datadefinition.filter(c => c.visible == true);
                            tFields = tFields.sort((a, b) => (a.displayorder > b.displayorder) ? 1 : ((b.displayorder > a.displayorder) ? -1 : 0));
                            var i;
                            for (i = 0; i < tFields.length; i++) {                              
                                var tField = {};
                                tField.label = tFields[i].label;                                
                                tField.key = tFields[i].csfield.toLowerCase(); 
                                //tField.sortable = true;
                                tField.sortable = false;
                                //Per gestione filtri colonna
                                tField.filters = [];
                                tField.rifelement = tFields[i].id;

                                if (tFields[i].sqltype == 'bit') {
                                    tField.formatter = (value) => {
                                        return value ? 'Yes' : 'No'
                                    }
                                }

                                if (tFields[i].sqltype == 'date') {
                                    tField.formatter = (value) => {
                                        return value !== null ? moment(value).format('YYYY-MM-DD') : '';
                                    }
                                }

                                if (tFields[i].sqltype == 'datetime2') {
                                    tField.formatter = (value) => {
                                        return value !== null ? moment(value).format('YYYY-MM-DD HH:mm') : '';
                                    }
                                }

                                if (tFields[i].sqltype == 'int')  {
                                    tField.tdClass = 'text-right';                                   
                                }


                                if (tFields[i].sqlcolumn == "BITPROP") {
                                    tField.tdClass = 'text-center';                                    
                                }


                           
                                // Non visualizzo i campi per i quali esiste una reference
                                if ((!tFields[i].reference) && (tFields[i].csfield != '_bgcolor')) {
                                    //console.log(tFields[i].csfield);
                                    tField.visible = true;
                                    self.nonFixedFields.push({...tField, sortable: true});
                                }
                                
                            }
 

                            // Aggiorno Datatable
                            self.GetData();

                        }).catch(e => {
                            this.errors.push(e)
                            self.isLoading = false;
                        });
                    // new

                }
            },
            search: function () {
                console.log(this.search);                                     
                //debounce...
                if (!this.awaitingSearch) {
                    setTimeout(() => {                        
                        if (!this.isLoading) {
                            this.GetData();                            
                        }
                        this.awaitingSearch = false;
                    }, 700); // 0,5 sec delay
                
                }
            
                
                this.awaitingSearch = true;
            },
            $route(to, from) {
                console.log('Route changed from ' + from.path + ' to ' + to.path);
            }


        },

        methods: {
            DeleteSelected(parAskConfirm) {
                this.SwiDeleteAskConfirm = parAskConfirm;                
                if (!parAskConfirm) {
                    let self = this;                    
                    var tKeys = [];
                    self.SelectedRows.forEach(row => {                        
                        var tKey = this.GetKey(row);  
                        tKeys.push(tKey);                        
                    });                                            
                    var Link = 'api/' + self.SelectedEntity.controller + '/multi';
                    //console.log(tKeys);                    
                    axios.delete(Link, { data:  tKeys , headers: this.$store.state.Headers  })
                        .then(() => {                            
                            this.GetData();
                        })
                        .catch(e => {
                            console.log(e.response.data);                            
                            this.errors.push({ message: e.response.data.title });                            
                            var tErr = Error();
                            tErr.message = "Request failed with status code " + e.response.data.status;
                            this.errors.push(tErr);
                        });
                }
                
                
            },
            ExportFile(ExportType) {
                //console.log('export excel');
                this.GetData(true, ExportType);
            },  
            OnRowSelected(parSelectedItems) {
                //console.log(items);
                this.SelectedRows = parSelectedItems;                
            },
            ModalRowSelected() {                
                //Se sono in modale il ritorno il record selezionato
                if (this.isModal)
                {                       
                    this.$emit('select', this.SelectedRows[0]);                   
                }
            },
            OpenEdit: function (parMulti) {
                console.log('OpenEdit');

                //Da modificare, il template viene richiesto anche dentro l'edit....
                let self = this;                
                var Link = 'api/' + self.SelectedEntity.controller + '/createtemplate/';
                axios.get(Link, this.$store.state.Headers)
                    .then(response => {
                        self.Template = response.data.Result;   

                        var tId = this.Windows.length + 1;
                        var tWin = {};
                        tWin.id = tId;
                        tWin.SelectedRowId = '';
                        tWin.SwiNew = true;
                        tWin.SwiClone = false;
                        tWin.SwiMulti = parMulti;                        
                        tWin.RifEntity = this.rifentity;
                        tWin.Row = this.GetRow(tWin.SelectedRowId, tWin.SwiNew);
                        tWin.TemplateFilters = this.Filters;
                        this.Windows.push(tWin);
                    }).catch(e => {
                        this.errors.push(e);                        
                    });
                
              
            },
            ChangePage(pag) {
                this.PageNumber = pag;
                this.GetData();
            },
           
            GetColumnDescription: function (colname) {
                return this.datadefinition.find(c => c.csfield.toLowerCase() === colname.toLowerCase()).label;
            },
            GetData(SwiExportExcel,ExportType) {                
                console.log('GetData');                
                this.SelectedRows = [];
                this.errors = [];
                let self = this;                
                self.isLoading = true;         
                self.ShowColumnFilter = false;

                var tApiPrefix = 'api/';

                if (SwiExportExcel) {
                    console.log('export excel');
                    tApiPrefix = 'file/';
                }

                if ((this.HasReqFilters) & (this.Filters.length == 0)) {
                    this.isLoading = false;
                    this.datatable = [];
                    return;
                }
                
                //var Link = tApiPrefix + (self.SelectedEntity.fkcompany == -1 ? '_main' : this.$store.state.Company.csnamespace) + '/' + self.SelectedEntity.csclass + '/detailed/filtered/';
                var Link = tApiPrefix + self.SelectedEntity.controller + '/detailed/filtered/';
                var tReqBody = {};
            

                if (self.search != '') {
                    console.log('attivo ricerca per parola chiave : ' + self.search);                    
                    tReqBody.search = self.search;                    
                }
                // Memorizzo il valore di search per sapere se è cambiato mentre scaricavo i dati
                let tSearch = self.search;                    

                // Paginazione
                if (this.$store.state.PageSize != 0) {
                    self.SwiPaginazione = true;
                    tReqBody.pagination = {};
                    tReqBody.pagination.pageNumber = self.PageNumber;
                    tReqBody.pagination.pageSize = this.$store.state.PageSize;
                    tReqBody.pagination.includeCount = true;
                }
                else {
                    self.SwiPaginazione = false;
                }

                if (SwiExportExcel) {
                    tReqBody.filetype = ExportType;
                }
                                
                
                self.AllFilters = [];
                //Filtri Testata
                self.AllFilters.push(...self.Filters);
                //Filtri Colonne                
                this.fields.forEach(element => this.AddColumnFilter(element));

                tReqBody.filters = self.AllFilters;
                                
                var tOptions = {
                    url: Link,
                    method: 'post',
                    data: tReqBody,
                    headers: this.$store.state.Headers
                    //responseType: 'blob'
                };
                // Modifico alcuni parametri in caso di Export Excel
                if (SwiExportExcel) {
                    tOptions.responseType = 'blob';
                    tOptions.data.pagination = null; //non voglio paginazione
                }
                axios(tOptions).then(response => {
                        var tHeader;
                        if (SwiExportExcel) {
                            //Esporto Excel
                            tHeader = response.headers;                            
                            var tContent = response.headers["content-disposition"].split("filename=")[1];
                            tContent = tContent.split(";")[0];
                            console.log(tContent);

                            console.log('response export excel');
                            var fileURL = window.URL.createObjectURL(new Blob([response.data]));                            
                            var fileLink = document.createElement('a');

                            fileLink.href = fileURL;
                            fileLink.setAttribute('download', tContent);
                            document.body.appendChild(fileLink);

                            fileLink.click();
                        }
                        else {

                            //controllo
                            if (tSearch != self.search) {
                                console.log('Rilancio la ricerca !');
                                this.GetData(SwiExportExcel); 
                            }

                            // Gestione della paginazione
                            tHeader = response.headers;
                            var tPagination = JSON.parse(tHeader["x-pagination"]);
                            //self.PageNumber = tPagination.PageNumber;
                            //self.PageSize = tPagination.PageSize;
                            self.TotalRecords = tPagination.TotalRecords;
                            self.TotalPages = tPagination.TotalPages;


                            // Aggiorno Griglia e Variabili                             
                            //console.log('prima :' + tPagination.PageSize);
                            //console.log(tPagination);
                            if (tPagination.PageSize < 10000) {
                                self.datatable = response.data.Result;
                            }
                            else {
                                console.log('top 5k righe');
                                self.datatable = response.data.Result.slice(0, 2000);
                            }
                            
                            //console.log('dopo');

                        }
                                                
                        
                        self.isLoading = false;
                                               


                    }).catch(e => {
                        this.errors.push(e)
                        self.isLoading = false;
                    });
            },
            GetFilterElements: function (ColumnName) {                
                return [... new Set(this.datatable.map(data => data[ColumnName]))];
            },
            Edit(row) {
                //this.SwiEdit = false;                
                //this.SelectedRowId = this.GetKey(row).toString();                
                //this.SwiEdit = true;                

                // nuova versione con finestre multiple
                console.log('Edit');
                var tId = this.Windows.length + 1;
                var tWin = {};
                tWin.id = tId;
                tWin.SelectedRowId = this.GetKey(row).toString();                
                tWin.SwiNew = false;
                tWin.SwiClone = false;
                tWin.SwiMulti = false;
                //tWin.SwiEdit = true; // Serve ?
                tWin.RifEntity = this.rifentity;     
                tWin.Row = this.GetRow(tWin.SelectedRowId, tWin.SwiNew);
                tWin.TemplateFilters = this.Filters;
                this.Windows.push(tWin);
            },
            RunJob() {
                console.log('run job : ' + this.RifJob);
                                
                let self = this;

                let jsonParams = {};

                self.Filters.forEach(element => {
                    var tPropName = this.$store.state.ElementsAll.find(E => E.id === element.managedEntityElement);
                    var tValue = '';
                    if (element.bigintValues.length > 0) {
                        tValue = element.bigintValues[0];
                    }
                    else {
                        tValue = element.nvarcharValues[0];
                    }
                    jsonParams[tPropName.csfield] = tValue;                    
                });    

                console.log(jsonParams);
                                

                var Link = 'api/_main/Jobs/runjob/' + self.RifJob;
                axios.post(Link, "jsonParams=" + JSON.stringify(jsonParams), this.$store.state.Headers)
                    .then(response => {                        
                        self.RifJobExec = response.data.Result;
                        //console.log('Response RifJobidjob : ' + tResultId);                        
                    })
                    .catch(e => {
                        this.errors.push(e)
                    })

            },
            GetKey: function (row) {    

                // Ritorno il valore partendo dal nome prop, faccio una ricerca case insensitive
                return row[Object.keys(row).find(key => key.toLowerCase() === this.keypropdef.csfield.toLowerCase())];                
            },
            GetRow: function (RowId,parSwiNew) {
                if (parSwiNew) {
                    return this.Template;
                }
                else {
                    // Ritorno l'intera riga partendo dall'id       
                    return this.datatable.find(r => r[this.keypropdef.csfield.toLowerCase()] == RowId);                
                }
                
            },                        
            AddColumnFilter: function (parField) {
                //console.log('AddColumnFilter');                

                if (parField.filters.length > 0) {
                    var Filter = {};
                    Filter.managedEntityElement = parField.rifelement;
                    //console.log('rifelement : ' + parField.rifelement)
                    Filter.inverse = false;
                    Filter.bigintValues = [];                    
                    Filter.nvarcharValues = [];                    
                    Filter.baseLevel = false;

                    var tSqltype = this.datadefinition.find(e => e.id == parField.rifelement).sqltype;
                    //console.log(tSqltype);

                    if (tSqltype == 'bit') {
                        parField.filters.forEach(element => {                            
                            Filter.bigintValues.push(element ? 1 : 0);                            
                        });                        
                    }

                    if (tSqltype == 'int') {
                        Filter.bigintValues = parField.filters;                        
                    }

                    if ((tSqltype == 'varchar') || (tSqltype == 'nvarchar'))  {
                        Filter.nvarcharValues = parField.filters;
                    }

                    if (tSqltype == 'decimal') {
                        // I decimal vengono gestiti come stringhe                        
                        //console.log('Filter: Decimal value');
                        Filter.nvarcharValues = parField.filters;
                    }
                                        
                    this.AllFilters.push(Filter);                    
                }

            },
            GetNewFilter: function (parRifElement, Value) {
                console.log('parRifElement : ' + parRifElement);
                var tSqltype = this.$store.state.ElementsAll.find(E => E.id === parRifElement).sqltype;
                console.log('tSqltype : ' + tSqltype);
                //var tSqltype = this.datadefinition.find(e => e.id == parRifElement).sqltype;
                var Filter = {};
                Filter.managedEntityElement = parRifElement;
                //console.log('rifelement : ' + parField.rifelement)
                Filter.inverse = false;
                Filter.bigintValues = [];
                Filter.nvarcharValues = [];    

                var FilterValues = [];

                FilterValues.push(Value);
                console.log('FilterValues');
                console.log(FilterValues);

                if ((tSqltype == 'varchar') || (tSqltype == 'nvarchar')) {
                    Filter.nvarcharValues = FilterValues;
                }

                if ((tSqltype == 'int') || (tSqltype == 'decimal')) {
                    Filter.bigintValues = FilterValues;
                }

                return Filter;
            },            
            OnSetFilters(ok, parFilters) {
                //console.log('OnSetFilters ' + ok);
                if (ok) {
                    this.Filters = parFilters;
                    //console.log('Num. Filtri : ' + parFilters.length);
                    this.GetData();
                }                
            },
            onHideEdit(parResponse) {                
                this.SwiEdit = false;
                this.SwiNew = false;
                
                if ((parResponse.SwiSaved) && (!parResponse.SwiMulti)){                      
                    this.GetData();
                    //Verifico se è un Edit richiamato da un altro Edit
                    var tWin = this.Windows.find(e => e.id == (parResponse.Winid));
                    if (tWin.RifElement)
                    {
                        //Identifico Window di Origine e Window appena chiusa
                        var tWinOri = this.Windows.find(e => e.id == (parResponse.Winid-1));
                        
                        //Determino nuovo valore proveniente da finestra appena chiusa con creazione record
                        var tRifEnt = this.$store.state.ElementsAll.find(E => E.id === tWin.RifElement);
                        var tNewValue =  parResponse.Row[tRifEnt.csfield.toLowerCase()];
                        //console.log(tRifEnt.csfield.toLowerCase());
                        //console.log(tNewValue);
                        
                        //Determino elemento dell'oggetto in edit della maschera precedente al quale aggiornare il valore
                        var tRifEntEdit = this.$store.state.ElementsAll.find(E => E.reference === tWin.RifElement &&  E.fkmanagedentity == tWinOri.RifEntity);                        
                        //console.log(tWin.RifElement);
                        //console.log(tWin.RifEntity);                        
                        //console.log(tRifEntEdit);
                        //Aggiorno l'oggetto con il nuovo valore
                        tWinOri.Row[tRifEntEdit.csfield.toLowerCase()] = tNewValue;

                    }
                    
                    //dddddddddddddddddddparResponse
                }                
                this.SelectedRowId = '';

                if (parResponse.SwiMulti) {
                    this.SetMultiEdit(parResponse.Row);
                }

      

                //In realtà c'è un bug, viene eliminata sempre l'ultima, ma essendo modali non si riscontra il bug !!!
                // Rimuovo la finestra modale appena chiusa dall'elenco
                var tIndex = this.Windows.findIndex(obj => obj.id === parResponse.Winid);
                this.Windows.splice(tIndex, 1);
                
            },            
            onNewComboElement(parRifElem, parRifClone) {
                //Determino Entity correlata
                //console.log('onNewComboElement ', parRifElem );
                var tRifEnt = this.$store.state.ElementsAll.find(E => E.id === parRifElem).fkmanagedentity;
                var tId = this.Windows.length + 1;
                var tWin = {};
                tWin.id = tId;
                tWin.SwiNew = true;
                //tWin.SwiEdit = true; // Serve ?
                tWin.SwiClone = false;
                tWin.SwiMulti = false;
                tWin.RifEntity = tRifEnt;
                tWin.RifElement = parRifElem;
                tWin.SelectedRowId = '';                
                
                if (parRifClone != 0) {
                    //Nuova Anagrafica - da Clonare  
                    //Creo Filtro
                    var tFilter = this.GetNewFilter(parRifElem, parRifClone);
                    //console.log('prova');
                    //console.log(tFilter);
                    var tFilters = [];
                    tFilters.push(tFilter);
                    //Scarico Riga da Clonare                    
                    //var tEntityClass = this.$store.state.Entity.find(E => E.id === tRifEnt).csclass;    
                    var tEntityClass = this.$store.state.Entity.find(E => E.id === tRifEnt);    

                   
                    //var Link = 'api/' + (this.SelectedEntity.fkcompany == -1 ? '_main' : this.$store.state.Company.csnamespace) + '/' + tEntityClass + '/detailed/filtered/';
                    var Link = 'api/' + tEntityClass.controller + '/detailed/filtered/';


                    var tReqBody = {};
                    if (this.$store.state.PageSize > 0) {
                        tReqBody.pagination = {};
                        tReqBody.pagination.pageNumber = self.PageNumber;
                        tReqBody.pagination.pageSize = this.$store.state.PageSize;
                        tReqBody.pagination.includeCount = true;
                    }
                    tReqBody.filters = tFilters;
                    axios.post(Link, tReqBody, this.$store.state.Headers)
                        .then(response => {                            
                            tWin.Row = response.data.Result[0];
                            tWin.SwiNew = true;
                            tWin.SwiClone = true;
                            tWin.SwiMulti = false;
                            tWin.SelectedRowId = parRifClone.toString();
                            this.Windows.push(tWin);
                        }).catch(e => {
                            this.errors.push(e);                            
                        });
                }
                else {
                    //Nuova Anagrafica - Vuota
                    //Il template viene valorizzato nella EDIT
                    //tWin.Row = this.GetRow(tWin.SelectedRowId, tWin.SwiNew);
                    tWin.Row = {};
                    tWin.TemplateFilters = this.Filters;
                    this.Windows.push(tWin);
                }
                                
            },
            OpenColumnFilter(columnname, evt) {
                evt.preventDefault();
                evt.stopImmediatePropagation();
                this.ShowColumnFilter = true;
                this.SelectedColumnName = columnname;
            },
            RemoveFilters() {

                this.fields.forEach(element => {
                    element.filters = [];
                });
                this.GetData();

            },
            RowClass(item) {
                //Return background class
                if (Object.prototype.hasOwnProperty.call(item, '_bgcolor')) {
                    return item._bgcolor +' text-left';
                }
                return 'text-left';
            },
            SaveRow(Row) {
                let self = this;
                //var Link = 'api/' + (self.SelectedEntity.fkcompany == -1 ? '_main' : this.$store.state.Company.csnamespace) + '/' + self.SelectedEntity.csclass;                
                var Link = 'api/' + self.SelectedEntity.controller;
                axios.put(Link, Row, this.$store.state.Headers)
                    .then(response => {
                        if (!response.data.isError) {
                            console.log('errore in save');
                        }
                    })
                    .catch(e => {
                        this.errors.push(e);
                    })
            },
            SetMultiEdit(parRow) {
                //console.log('SetMultiEdit');
                let self = this;
                this.datatable.forEach(tRow => {
                    this.datadefinition.forEach(tProp => {                        
                        // Combo
                        if (Object.prototype.hasOwnProperty.call(tProp, 'reference')) {
                            if (Object.prototype.hasOwnProperty.call(parRow, tProp.csfield)) {                                
                                if ((parRow[tProp.csfield] != null) && (parRow[tProp.csfield] != 0)) {                          
                                    tRow[tProp.csfield] = parRow[tProp.csfield];
                                }
                            }
                        }
                        //Bitprop
                        if (tProp.csfield.toLowerCase() == 'bitprop') {
                            if (Object.prototype.hasOwnProperty.call(parRow, tProp.csfield)) {                                
                                if (parRow[tProp.csfield] != null) {
                                    tRow[tProp.csfield] = parRow[tProp.csfield];
                                }
                            }
                        }


                    });
                });
                //Passo l'intero datatable per l'aggiornamento, a meno che non abbia selezionato delle righe ed allora passo solo quelle
                var tData = this.datatable;
                if (this.SelectedRows.length > 0) {
                    tData = this.SelectedRows;
                }
                var Link = 'api/' + self.SelectedEntity.controller + '/multi';
                axios.put(Link, tData, this.$store.state.Headers)
                    .then(response => {
                        console.log(response.data.OK);
                        //console.log('ok save ok');
                        this.GetData();
                    })
                    .catch(e => {
                        var tErr = Error();
                        tErr.message = "Request failed with status code " + e.response.data.status;
                        this.errors.push(tErr);
                    });
            },
            SetPageSize(numrec) {
                this.$store.commit('SetPageSize', numrec);                
                //this.PageSize = numrec;
                this.GetData();
            }            
        },
        mounted() {
            console.log('mounted');
        },
        updated() {
            //console.log(this.$route)
        }

        
    }


</script>
