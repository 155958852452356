<template id="Oops">
    <h1>
        <font-awesome-icon icon="frown" size="4x" />
        <br />Oops !
        <br />
        <div v-if="$cookies.isKey('aydm')">

            <b-button variant="outline-success" @click="Reload()">Reload data</b-button>
        </div>

        <br />
        <div v-if="$cookies.isKey('aydm')">
            <b-button variant="outline-primary" @click="Logout()">Logout</b-button>
        </div>
        <div v-else>
            <router-link style="width: 100%; display: block;" :to="{ path: '*'} ">Login</router-link>
        </div>        
    </h1>
</template>

<script>    

    export default {
        name: 'Oops',
        props: {
        },
        data() {
            return {                
            };
        },
        components: {            
        },
        watch: {
        },

        methods: {
            Logout() {

                if (this.$store.state.LoggedIn) {
                    console.log('logout 1 ' + this.$cookies.get('aydm').Token);
                    this.$store.commit('SetLogout', this.$cookies.get('aydm').Token);
                    console.log('logout 2');
                }
                                
                this.$cookies.remove('aydm');
                //console.log({ router: this.$router });
                this.$router.push({ path: '*' });
            },
            Reload() {
                this.$store.commit('SetLoginParams', this.$cookies.get('aydm'));     
                this.$store.dispatch('GetCompanies');
                //this.$store.commit('SetCompany', -1); 
                this.$router.push({ path: '*' });
            }
        },
        mounted() {            
        },
        updated() {            
        }
    }


</script>