<template id="AnaModal">

<div>

    <div v-if="ShowModal" class="msgbox">
        <div class="ay-msgbox-content  rounded" >
            <div class="ay-msgbox-header ">
                <div class="row">
                    <div class="col-11 "><h4>{{ this.$store.state.Entity.find(E => E.id === this.Rifentity).label }}</h4></div>                    
                    <div class="col"> <button  class = "close" title="close modal-header"   style="text-align: right; margin-right: 2px;" @click="onClose()">×</button> </div>
                    
                </div>                       
            </div>                                          
            <ListAna :rifentity=Rifentity isModal v-on:select="onSelect"></ListAna>
        </div>
    </div>

</div>
    

</template>

<style>
    .msgbox {
        display: block; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 999; /* Sit on top */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    }

    /* Modal Content/Box */
    .ay-msgbox-header {        
        justify-content: space-between;     
        border-bottom: 1px solid #dee2e6;
        border-top-left-radius: calc(0.3rem - 1px);
        border-top-right-radius: calc(0.3rem - 1px);
    }

    /* Modal Content/Box */
    .ay-msgbox-content {
        background-color: #fefefe;
        margin:2% 5% 2% 5%; /* 15% from the top and centered */
        padding: 20px;
        border: 1px solid #888;
        
    }

</style>

<script>

    export default {
        name: 'AnaModal',
        components: {    
            ListAna: () => import('./ListAna.vue')                              
        },
        props: {
            Rifentity: {
                type: Number,
                required: true
            } 
        },
        data() {
            return {                
                ComboFilters: [],
                errors: [],
                isLoading: true,
                ShowModal: false,
                Elements: Object,
                ErrorResponse: '',                
                ComboElements: [],           
                pRow: {},
                SelectedEntity: Object,
                SwiDel: false
            };
        },
        watch: {
            Rifentity: {
                immediate: true,
                handler(newVal) {
                    if (newVal)
                    {
                        console.log('Shoooooow');
                        this.ShowModal = true;
                    }
                                
                }
            },
            // ShowModal: {
            //     immediate: true,
            //     handler(newVal) {
            //         if (newVal == true) {
            //             console.log('showdinuovo');

            //         }
            //     }
            // }
        },
        methods: {
            onClose() {  
                this.ShowModal = false;        
                this.$emit('hidemodal');                
            },
            onSubmit() {
                this.$emit('hidemodal');                
            },
            onSelect(rifrec) {
                this.ShowModal = false;                    
                //console.log(rifrec);
                this.$emit('select', rifrec);                
            }            
        },
        mounted() {
            //console.log('anamodal');            
        }

    }
</script>
