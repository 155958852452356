<template id="ChangePassword">
    <div>

        <!-- Errori -->
        <b-alert v-if="errors && errors.length" variant="danger" show dismissible>
            <ul>
                <li v-for="error of errors" :key="error.id">
                    Errore : {{error.message}}
                </li>
            </ul>
        </b-alert>

        <!--Spinner-->
        <div v-if="this.$store.state.isLoading" id="div-spinner" style="position:fixed;top:50%;left:50%">
            <b-spinner variant="primary" style="width: 3rem; height: 3rem;" label="$t('messages.RicercaInCorso')"></b-spinner>
        </div>

        <h1>Change Password</h1>

        <b-alert variant="warning" show v-if="ChangePasswordErrorResponse != ''">  <font-awesome-icon icon="exclamation-circle" transform="shrink-1 left-6" /> {{ChangePasswordErrorResponse}}</b-alert>
        <b-alert variant="success" show v-if="ChangePasswordResponse != ''">  <font-awesome-icon icon="exclamation-circle" transform="shrink-1 left-6" /> {{ChangePasswordResponse}}</b-alert>

        <b-container v-if="this.$store.state.LoggedIn">

            <b-form>
                <b-form-group id="input-group-1"
                              label="Type your current password"
                              label-for="input-1">
                    <b-form-input id="input-1"
                                  v-model="CurrentPassword"
                                  type="password"></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-2" label="Type your new password" label-for="input-2" description="Choose a unique password to protect your account">
                    <b-form-input id="input-2"
                                  v-model="NewPassword"
                                  type="password"
                                  required></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-3" label="Retype your new password" label-for="input-3">
                    <b-form-input id="input-3"
                                  v-model="RetypePassword"
                                  type="password"
                                  required></b-form-input>
                </b-form-group>


                <b-button @click="ChangePassword" variant="primary">Change password</b-button>
            </b-form>
        </b-container>
        <div v-else>
            <Oops></Oops>
        </div>
    </div>        

</template>


<script>        
    import axios from 'axios';
    import Oops from './Oops.vue';
    

    export default {  
        name: 'ChangePassword',
        data() {
            return {
                errors: [],  
                isLoading: false,
                CurrentPassword: '',
                NewPassword: '',
                RetypePassword: '',                
                ChangePasswordErrorResponse: '',
                ChangePasswordResponse: '' 
            };
        },
        components: {
            Oops
        },
        watch: {            
          
        },
        methods: {
            ChangePassword() {                                
                this.ChangePasswordErrorResponse = '';
                this.ChangePasswordResponse = '';
                if (this.NewPassword != this.RetypePassword) {
                    this.ChangePasswordErrorResponse = 'New password and Confirm password does not match';
                    return;
                }
                if (this.NewPassword == this.CurrentPassword) {
                    this.ChangePasswordErrorResponse = 'Your new password cannot be the same as current password';
                    return;
                }


                let self = this;
                self.isLoading = true;                
                self.errors = [];
                var Link = 'api/_main/Account/changepassword';
                let data = {
                    oldPassword: this.CurrentPassword,
                    newPassword: this.NewPassword                    
                }
                axios.post(Link, data)
                    .then(response => {       
                        self.errors = [];
                        console.log(response.data);
                        self.ChangePasswordResponse = response.data.Message;           
                        self.CurrentPassword = '';
                        self.newPassword = '';
                        self.RetypePassword = '';
                        self.isLoading = false;
                        
                    })
                    .catch(e => {  
                        self.isLoading = false;
                        if (e.response) {
                            if (Object.prototype.hasOwnProperty.call(e.response.data, 'isError')) {
                                self.ChangePasswordErrorResponse = e.response.data.title;
                            }
                            else {
                                this.errors.push(e)
                            }
                        }
                    })

            }
    
     
        },
        mounted() {
            console.log('mounted');
        },
        updated() {            
        }

        
    }


</script>