<template id="ListImports">
    <div>
        <!-- Errori -->
        <b-alert v-if="errors && errors.length" variant="danger" show dismissible>
            <ul>
                <li v-for="error of errors" :key="error.id">
                    Errore : {{error.message}}
                </li>
            </ul>
        </b-alert>



        <b-card-group columns>
            <template v-for="(rifjob, index) in this.RunningJobs">
                <JobStatusCard :rifjob="rifjob" :key="index"></JobStatusCard>
            </template>
        </b-card-group>



        <b-modal v-if="SelectedLayout"
                 id="id_modal_edit"
                 ref="modal"
                 size="lg">

            <div slot="modal-title">
                {{SelectedLayout.label}}
            </div>

            Required Parameters :
            <div class="row">
                <div class="col-sm-3"></div>
                <div class="col-sm-6">
                    <b-form-group label="Month"
                                  label-cols-sm="4"
                                  label-cols-lg="3"
                                  label-for="input-month"
                                  :horizontal="true">

                        <model-list-select :list="Months"
                                           :isDisabled=false
                                           v-model="SelectedMonth"
                                           option-value="code"
                                           option-text="desc"
                                           placeholder="Select..">
                        </model-list-select>
                    </b-form-group>

                    <b-form-group label="Year"
                                  label-cols-sm="4"
                                  label-cols-lg="3"
                                  label-for="input-year"
                                  :horizontal="true">
                        <b-form-input id="input-year"
                                      v-model="SelectedYear"
                                      :state="CheckYear"
                                      type="number">
                        </b-form-input>
                    </b-form-group>
                </div>
                <div class="col-sm-3"></div>
            </div>



            Select a file and press Upload to schedule the import.
            <div v-if="currentFile" class="progress">
                <div class="progress-bar progress-bar-info progress-bar-striped"
                     role="progressbar"
                     :aria-valuenow="progress"
                     aria-valuemin="0"
                     aria-valuemax="100"
                     :style="{ width: progress + '%' }">
                    {{ progress }}%
                </div>
            </div>

            <label class="btn btn-default">
                <input type="file" id="files" ref="files" @change="selectFile" />
            </label>



            <div class="alert alert-light" role="alert">{{ message }}</div>

            <template v-slot:modal-footer="{ ok, cancel }">

                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button variant="success" @click="UploadFile">
                    Upload
                </b-button>
                <b-button variant="secondary" @click="cancel()">
                    Cancel
                </b-button>

            </template>


        </b-modal>

       

        <h1>File Imports</h1>
        <div class="container">
            <b-table :items="this.$store.state.FileLoaderLayouts" :primary-key="'id'" :fields="fields" class="table_Cell">
                <!-- A virtual composite column -->
                <template v-slot:cell(id)="data">
                    <font-awesome-icon size="lg" icon="play" @click="SelectLayout(data.item)" style="color:forestgreen" />
                </template>
            </b-table>
        </div>


    </div>
</template>

<script>
    import axios from 'axios';
    import UploadService from "../UploadFilesService";
    import JobStatusCard from './JobStatusCard.vue';
    import { ModelListSelect } from 'vue-search-select';


    export default {
        data() {
            return {
                errors: [],
                fields: [
                    {
                        key: 'label',
                        label: 'Description',
                        sortable: true
                    },
                    {
                        key: 'id',
                        sortable: false
                    },
                    
                ],
                Months: [
                    {code: 0,desc: 'Not Set'},
                    {code: 1, desc: '01' },
                    {code: 2, desc: '02' },
                    {code: 3, desc: '03' },
                    {code: 4, desc: '04' },
                    {code: 5, desc: '05' },
                    {code: 6, desc: '06' },
                    {code: 7, desc: '07' },
                    {code: 8, desc: '08' },
                    {code: 9, desc: '09' },
                    {code: 10, desc: '10' },
                    { code: 11, desc: '11' },
                    { code: 12, desc: '12' }
                ],
                JobStatus: {},
                SelectedLayout: undefined,
                SelectedMonth: 0,
                SelectedYear:0,
                selectedFiles: undefined,
                currentFile: undefined,
                progress: 0,
                message: '',
                RunningJobs: []
            }
        },
        computed: {
            CheckYear() {
                return this.SelectedYear > 2010 ? true : false
            }
        },
        components: {
            JobStatusCard,
            ModelListSelect
        },
        methods: {
            selectFile() {
                console.log('selectFile');
                this.selectedFiles = this.$refs.files.files;
                console.log(this.selectedFiles.length);
            },
            UploadFile() {
                this.progress = 0;
                this.message = '';
                let self = this;
                this.currentFile = this.selectedFiles[0];      

                // Parametri import
                var tParams = {};
                tParams.month = self.SelectedMonth;
                tParams.year = self.SelectedYear;
                                
                var Link = 'api/_main/Jobs/loadfile/' + this.SelectedLayout.id;
                UploadService.upload(Link, this.currentFile, tParams, this.$store.state.Token, event => {
                    this.progress = Math.round((100 * event.loaded) / event.total);
                })
                    .then(response => {

                        var tResultId = response.data.Result[0].generatedJobId;                        
                        console.log('Response idjob : ' + tResultId);
                        self.GetJobStatus(tResultId);
                        self.RunningJobs.push(tResultId);

                    })             
                    .catch(() => {
                        this.progress = 0;
                        this.message = "Could not upload the file!";
                        this.currentFile = undefined;
                    });

                this.selectedFiles = undefined;
                this.SelectedLayout = undefined;
            },        
            SelectLayout: function (Layout) {
                console.log(Layout);
                this.SelectedLayout = Layout;
                console.log('idjob : ' + Layout.id);
                this.$bvModal.show('id_modal_edit');
           
            },
            GetJobStatus: function (idjobstatus) {
                console.log('GetJobStatus idjob : ' + idjobstatus);
                if (idjobstatus) {
                    console.log('GetJobStatus : ' + idjobstatus);
                    let self = this;
                    var Link = 'api/_main/SchedulerJob/' + idjobstatus;
                    axios.get(Link, this.$store.state.Headers)
                        .then(response => {
                            self.JobStatus = response.data.Result;
                            console.log(self.JobStatus);
                        }).catch(e => {
                            this.errors.push(e);
                        });
                }

            },
           
        },
        mounted() {
            var today = new Date();
            this.SelectedYear = today.getFullYear();            
        },
    }
</script>